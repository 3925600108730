import React, { forwardRef, useCallback } from "react";
import { useAsync } from "react-async";
import { MdLock, MdLockOpen } from "react-icons/md";
import {
  Icon,
  AlertDescription,
  AlertIcon,
  AlertProps,
  useToast,
  Tooltip,
  Box
} from "@chakra-ui/react";

import { useLocale } from "app/locale";
import { useAPI } from "api";
import { useAccount, useOrgLookup } from "app/auth-container";
import { hasPermission } from "app/permissions";
import { getAccountDisplayName } from "models/account";
import {
  Alert,
  Button,
  EditIcon,
  Heading3,
  InfoIcon,
  Spinner,
  useModal
} from "components/core";
import {
  CaseAccessControlList,
  DisplayRole
} from "components/cases/case-access-control-list";
import {
  DialogShareCasePublic,
  DialogShareCasePartner
} from "components/cases/dialog-share-case";
import { PartnerList } from "components/organizations/partner-list";
import { EmptyContent } from "components/common/empty-content";

export const CasePermissions = forwardRef(
  ({ pathologicCase }: { pathologicCase: any }, ref: any) => {
    const modal = useModal();
    const toast = useToast();
    const locale = useLocale();
    const { isSuper } = useAccount();
    const { getOrgById } = useOrgLookup();
    const orgName =
      getOrgById(pathologicCase.organizationId)?.name || locale.unknownOrg;
    const { data, isPending, error, reload } = useFetchPermissions(
      pathologicCase
    );

    if (error) {
      return (
        <Alert status="error" display="block">
          <Box>Unable to load case permissions</Box>
          {error.message && <Box>{error.message}</Box>}
        </Alert>
      );
    }

    if (isPending || !data) return <Spinner />;

    const sharePublic = async () => {
      const success = await modal.dialog({
        render: close => (
          <DialogShareCasePublic
            close={close}
            pathologicCase={pathologicCase}
            role={data.acl.defaultAccess.grantedRole}
            grantId={data.acl.defaultAccess.grantId}
          />
        ),
        modalProps: { size: "xl" }
      });
      if (!success) return false;
      toast({
        title: locale.casePermissionUpdated,
        description: `Case Number: ${pathologicCase.caseNumber}`,
        isClosable: true
      });
      reload();
    };

    const shareWithPartner = async partner => {
      const success = await modal.dialog({
        render: close => (
          <DialogShareCasePartner
            close={close}
            pathologicCase={pathologicCase}
            role={partner.grantedRole}
            grantId={partner.grantId}
            granteeId={partner.id}
            granteeName={getAccountDisplayName(partner, locale)}
          />
        ),
        modalProps: { size: "xl" }
      });
      if (!success) return false;
      toast({
        title: locale.casePermissionUpdated,
        description: `Case Number: ${pathologicCase.caseNumber}`,
        isClosable: true
      });
      reload();
    };

    const showPublicStatus = isSuper(); // Only super user can see the section "Private/Public Case", to be changed in the future
    const canShareWithPartner = hasPermission("case/share", pathologicCase);
    const {
      acl: { partnerAccounts, partnerOrgs }
    } = data;

    return (
      <Box pt={4}>
        {showPublicStatus && (
          <PublicSharingInfo
            pathologicCase={pathologicCase}
            role={data.acl.defaultAccess.grantedRole}
            mb={6}
            onShare={sharePublic}
          />
        )}
        <Heading3 mb={3} size="md">
          {locale.partnerUsersHeading}
        </Heading3>
        {partnerAccounts.length > 0 ? (
          <CaseAccessControlList
            partners={partnerAccounts}
            onChange={canShareWithPartner ? shareWithPartner : undefined}
          />
        ) : (
          <EmptyContent py={12}>
            {locale.noPartnerUsers({ orgName })}
          </EmptyContent>
        )}
        <Heading3 mt={6} mb={3} size="md">
          {locale.partnerOrgsHeading}
          <Tooltip
            label={locale.partnerOrgsExplanation}
            aria-label="Partner Users"
          >
            <Box as="span">
              <InfoIcon ml={2} color="gray.500" />
            </Box>
          </Tooltip>
        </Heading3>
        {partnerOrgs.length > 0 ? (
          <>
            <PartnerList partners={partnerOrgs} />
          </>
        ) : (
          <EmptyContent py={12}>
            {locale.noPartnerOrgs({ orgName })}
          </EmptyContent>
        )}
      </Box>
    );
  }
);

function useFetchPermissions(pathologicCase) {
  const { id: caseId, organizationId } = pathologicCase;
  const api = useAPI();
  const loadPermissions = useCallback(async () => {
    const { data: grants } = await api.cases.grants.list({ caseId });
    const { data: partners } = await api.orgs.partners.find({
      organizationId,
      page: 1,
      limit: 100
    });
    const acl = getCaseACL(partners, grants);
    return { grants, partners, acl };
  }, [api.cases.grants, api.orgs.partners, caseId, organizationId]);
  return useAsync(loadPermissions, { caseId });
}

function getCaseACL(partners: Medmain.Partner[], grants: Medmain.CaseGrant[]) {
  const findGrantByPartnerId = id => {
    return grants.find(({ granteeId }) => granteeId === id);
  };
  const defaultAccessGrant = grants.find(
    ({ granteeType }) => granteeType === "all"
  );
  const defaultAccess = {
    grantId: defaultAccessGrant?.id,
    grantedRole: defaultAccessGrant?.grantedRole || undefined
  };

  const partnerAccounts = partners
    .filter(({ type }) => type === "account")
    .map(accountPartner => {
      const { id, type, partner } = accountPartner as Medmain.AccountPartner;
      const foundGrant = findGrantByPartnerId(id);
      const grantedRole = foundGrant?.grantedRole;
      const grantId = foundGrant?.id;
      return { id, type, ...partner, grantedRole, grantId };
    });

  const partnerOrgs = partners.filter(({ type }) => type === "organization");

  return {
    partnerAccounts,
    partnerOrgs,
    defaultAccess
  };
}

const PublicSharingInfo = ({
  role,
  pathologicCase,
  onShare,
  ...props
}: {
  role?: Medmain.CaseGrant["grantedRole"];
  pathologicCase: Medmain.Case;
  onShare: () => void;
} & AlertProps) => {
  const locale = useLocale();
  const { caseNumber } = pathologicCase;

  if (!role) {
    return (
      <Alert status="info" bg="primary.50" {...props}>
        <Icon as={MdLock} boxSize="48px" color="primary.300" />
        <AlertDescription flexGrow={1}>
          {locale.privateCaseDescription({ caseNumber })}
        </AlertDescription>
        <Button primary onClick={onShare}>
          {locale.makePublicButton}
        </Button>
      </Alert>
    );
  }
  return (
    <Alert status="warning" {...props}>
      <AlertIcon as={MdLockOpen} boxSize="48px" mr={6} />
      <AlertDescription flexGrow={1}>
        {locale.anyUser} <DisplayRole role={role} />
      </AlertDescription>
      <Button primary leftIcon={<EditIcon />} width="120px" onClick={onShare}>
        {locale.editPermissionButton}
      </Button>
    </Alert>
  );
};
