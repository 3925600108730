import DOMPurify from "dompurify";
import React from "react";
import { AsyncState, useAsync } from "react-async";
import { AlertIcon, Box, StyleProps } from "@chakra-ui/react";
import styled from "@emotion/styled";

import { useAPI } from "api";
import { useLocale } from "app/locale";
import { Alert, Spinner } from "components/core";
import { PositionCenter } from "components/viewer/utils";
import { Terms } from "types";

type ChildrenProps = Pick<Terms, "body"> &
  Pick<AsyncState<Terms>, "error" | "isPending">;

type Props = {
  type: "service" | "ai";
  children?: (props: ChildrenProps) => React.ReactElement | null;
} & StyleProps;

export const FetchTermsData = (props: Props) => {
  const { type, children, ...styleProps } = props;
  const api = useAPI();

  const { data, error, isPending } = useAsync<Terms>(api.terms.get, {
    type
  });

  const childrenProps: ChildrenProps = {
    body: data?.body ?? "",
    error,
    isPending
  };
  return (
    children?.(childrenProps) ?? (
      <TermsContent {...childrenProps} {...styleProps} />
    )
  );
};

export const TermsContent = (props: ChildrenProps & StyleProps) => {
  const { body, isPending, error, ...styleProps } = props;
  const locale = useLocale();
  return (
    <Box
      w="100%"
      h="270px"
      p="15px 20px"
      border="1px solid #C2C8D0"
      borderRadius="1px"
      overflowY="scroll"
      {...styleProps}
    >
      {isPending ? (
        <PositionCenter>
          <Spinner />
        </PositionCenter>
      ) : error ? (
        <Alert status="error">
          <AlertIcon />
          {locale.todo("Load failed. Please try again.")}
        </Alert>
      ) : (
        <StyledTermsText
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
        />
      )}
    </Box>
  );
};

const StyledTermsText = styled(Box)`
  a {
    text-decoration: underline;
  }
  h2 {
    text-align: center;
    font-weight: bold;
  }
  ul {
    list-style-type: none;
    > li {
      position: relative;
      padding-left: 1rem;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
      }
    }
  }
  ol {
    list-style-type: none;
    counter-reset: count 0;
    > li {
      position: relative;
      padding-left: 2rem;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        counter-increment: count 1;
        content: "(" counter(count) ")";
      }
    }
  }
  dl {
    display: grid;
    grid-template-columns: max-content 1fr;

    > dd {
      position: relative;
      padding-left: 1rem;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        content: ":";
        margin-left: 0.3rem;
      }
    }
  }
  > section {
    counter-increment: section 1;
    > ol {
      > li {
        padding-left: 2.5rem;
      }
      > li::before {
        counter-increment: count 1;
        content: counter(section) "." counter(count);
      }
    }

    > div {
      margin-left: 2rem;
    }
  }
  > * + section {
    margin-top: 3rem;
  }
  > section + section {
    margin-top: 2rem;
  }
  > * + h2 {
    margin-top: 3rem;
  }
  > * + p {
    margin-top: 3rem;
  }
`;
