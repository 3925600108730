import React, { useState, useMemo } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  ButtonGroup
} from "@chakra-ui/react";
import { useLatest } from "react-use";
import { createContainer } from "unstated-next";

import { Button } from "./button";
import { ModalCloseButton } from "./close-button";

// The public imperative API to be used by components
export function useModal() {
  const { open } = ModalContainer.useContainer();

  const actions = useMemo(() => {
    function dialog<T = any>(options: Omit<Options, "resolve">) {
      return open<T>(options);
    }

    const alert = (
      content,
      { title = "", modalProps = {} as Options["modalProps"] } = {}
    ) => {
      return dialog({
        modalProps: modalProps,
        render: close => (
          <ModalAlert content={content} title={title} close={close} />
        )
      });
    };

    const confirm = (
      content,
      { title = "", modalProps = {} as Options["modalProps"] } = {}
    ) => {
      return dialog({
        modalProps: modalProps,
        render: close => (
          <ModalConfirm content={content} title={title} close={close} />
        )
      });
    };
    return { dialog, alert, confirm };
  }, [open]);

  return actions;
}

type Options<T = any> = {
  resolve: (value: T) => void;
  render: (close: (value?: T) => void) => React.ReactNode;
  modalProps?: Partial<Omit<React.ComponentProps<typeof Modal>, "children">>;
};
type ModalState = Options[];

function useModalInternalState() {
  const [modalState, setModalState] = useState<ModalState>([]);
  const modalStateRef = useLatest(modalState);

  const actions = useMemo(() => {
    function open<T>(options: Omit<Options, "resolve">) {
      return new Promise<T>(resolve => {
        setModalState(state => [...state, { ...options, resolve }]);
      });
    }

    const close = value => {
      const modal = modalStateRef.current[modalStateRef.current.length - 1];
      modal.resolve(value);
      setModalState(state => state.slice(0, -1));
    };
    return { open, close };
  }, [modalStateRef]);

  return {
    modalState,
    ...actions
  };
}

export const ModalContainer = createContainer(useModalInternalState);

export const ModalRoot = () => {
  const { close, modalState } = ModalContainer.useContainer();
  if (modalState.length === 0) return null;

  // about `z-index` on <ModalOverlay> and <ModalContent:
  // we override ChakraUI default values (1300 and 1400)
  // otherwise Antd `<Select>` drop-down menus don't show up.
  return (
    <>
      {modalState.map(({ modalProps, render }, id) => (
        <Modal
          key={`Modal-${id}`}
          isOpen
          onClose={() => close(false)}
          scrollBehavior="inside"
          {...modalProps}
        >
          <ModalOverlay zIndex={700} />
          <ModalContent zIndex={800} mx="15px" minW={0}>
            {render(close)}
          </ModalContent>
        </Modal>
      ))}
    </>
  );
};

const ModalAlert = ({ title, content, close }) => {
  return (
    <>
      {title && (
        <ModalHeader>
          {title}
          <ModalCloseButton />
        </ModalHeader>
      )}
      <ModalBody>{content}</ModalBody>
      <ModalFooter>
        <Button onClick={() => close(true)}>OK</Button>
      </ModalFooter>
    </>
  );
};

const ModalConfirm = ({ title, content, close }) => {
  return (
    <>
      {title && (
        <ModalHeader>
          {title}
          <ModalCloseButton />
        </ModalHeader>
      )}
      <ModalBody>{content}</ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={() => close(false)}>Cancel</Button>
          <Button primary onClick={() => close(true)}>
            OK
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};
