import ky, { Input, Options } from "ky";
import { stringify } from "qs";
import debugModule from "debug";
import merge from "lodash/merge";

import { getConfig } from "./config";
import { uploadFileByXHR } from "app/upload-file-xhr";
import { serializeSearchOptions } from "app/search";
import { checkAgreementTerms } from "app/terms";
import { ImageEditableData } from "app/upload-cases-images-container";
import { createValidationError } from "./api-error";
import {
  SearchOptions,
  AccountNames,
  AccountNamesByAdministrate,
  PaginatedItemList,
  Terms
} from "types";
import { CaseFormData } from "components/cases/case-form";
import { OrganizationFormData } from "components/organizations/organization-form";
import { ContractFormData } from "components/contracts/contract-form";
import { ColorSettingFormData } from "components/color-settings/color-setting-form";
import { DrawingSchema } from "components/viewer";
import {
  IIIFTileSource,
  IIIFTileSourceSchema
} from "components/viewer/layers/iiif-tile-source/iiif-tile-source";
import { ColorAdjustment } from "components/viewer/types";
import { ImageMeta, ImageMetaSchema } from "models/image-meta";

const debug = debugModule("medmain:api");

type AttachmentsListData = {
  caseId: string;
  attachments: Medmain.Attachment[];
};

export type ApiClientOptions = {
  getToken: (o?: GetTokenSilentlyOptions) => Promise<any>;
};

export type ApiClientReturn = ReturnType<typeof createAPIClient>;

type UploadOptions = {
  url: string;
  file: File;
  method?: "POST" | "PUT";
  onProgress: (p: number) => void;
};

export type MyColors = {
  saturation: number;
  brightness: number;
  contrast: number;
  gamma: number;
  tint: [number, number, number];
  invert: number;
};

function convertToColorAdjustment(data: MyColors) {
  return {
    bitmapSaturation: data.saturation,
    bitmapBrightness: data.brightness,
    bitmapContrast: data.contrast,
    bitmapGamma: data.gamma,
    bitmapTintColor: data.tint,
    bitmapInvert: data.invert === 1
  };
}

function convertToMyColors(data: ColorAdjustment) {
  return {
    saturation: data.bitmapSaturation,
    brightness: data.bitmapBrightness,
    contrast: data.bitmapContrast,
    gamma: data.bitmapGamma,
    tint: data.bitmapTintColor,
    invert: data.bitmapInvert ? 1 : 0
  };
}

// TODO Refactor api structure
export function createAPIClient({ getToken }: ApiClientOptions) {
  // TODO Fix this
  const {
    api: { rootURL }
  } = getConfig();

  // low-level function to make API calls, does not include any parsing of the response
  async function makeFetchRequest(
    endPointURL: string,
    options = {}
  ): Promise<Response> {
    const url = `${rootURL}/${endPointURL}`;

    const accessToken = await getToken();
    const defaultOptions = {
      headers: accessToken
        ? { Authorization: `Bearer ${accessToken}` }
        : undefined,
      hooks: {
        afterResponse: [checkAgreementTerms]
      }
    };
    const requestOptions = merge(defaultOptions, options); // Lodash `merge` perform a "deep merge" of the objects
    debug("API call", url, requestOptions);
    return await ky(url, requestOptions); // returns a standard Response object, like the browser native `fetch()`
  }

  // shortcut to make API calls and parse the result as JSON, to be used in 95% of the cases
  async function fetchJSON(endPointURL: string, options = {}): Promise<any> {
    const response = await makeFetchRequest(endPointURL, options);
    if (!response.ok) {
      if (response.status === 404) throw new Error("auth-error");
    }

    const contentType = response.headers.get("Content-Type");
    if (contentType !== "application/json") return;

    const data = await parseResponseToJSON(response);
    debug(`JSON response from "${endPointURL}"`, options, "=>", data);
    return data;
  }

  async function parseResponseToJSON(response) {
    const data = await response.json();

    if (data.errors) {
      const errorsByKey = data.errors.reduce(
        (acc, { field, message }) => ({
          ...acc,
          [field]: message
        }),
        {}
      );
      throw createValidationError("API validation error", errorsByKey);
    }

    if (!response.ok) {
      throw new Error(data.message || "Unexpected error");
    }

    return data;
  }

  async function makeRequestMergePatchJSON(endPointURL, options) {
    const { json, ...otherOptions } = options;
    return await makeFetchRequest(endPointURL, {
      method: "PATCH",
      headers: {
        "content-type": "application/merge-patch+json"
      },
      body: JSON.stringify(json), // we can't use `json` as usual because of the specific `content-type`
      ...otherOptions // allow customization
    });
  }

  /**
   * TODO
   * - Use following shortcuts for shorter lines and readability
   * - Actually deal with data.errors, data.message, 'unexpected error',
   *   and ky: HTTPError, TimeoutError, AbortError
   */
  // Base ky with rootURL and authorization configured
  const fetch = ky.create({
    prefixUrl: rootURL,
    timeout: 20_000,
    hooks: {
      beforeRequest: [
        async req =>
          req.headers.set("Authorization", `Bearer ${await getToken()}`)
      ],
      afterResponse: [checkAgreementTerms]
    }
  });

  // Extended ky configured for PATCH json with Content-Type: application/merge-patch+json
  const fetchPatch = fetch.extend({
    method: "patch",
    hooks: {
      beforeRequest: [
        req => req.headers.set("content-type", "application/merge-patch+json")
      ]
    }
  });

  // Shortcut for GET json
  const json = <T>(url: Input, options?: Options) =>
    fetch(url, options).json<T>();

  // Shortcut for PATCH json
  // eslint-disable-next-line
  const patchJson = <T>(url: Input, options: Options & { json: unknown }) =>
    fetchPatch(url, options).json<T>();

  // Upload file using XHR (for onProgress)
  // eslint-disable-next-line
  async function upload<T = any>(options: UploadOptions) {
    return uploadFileByXHR<T>({
      ...options,
      url: `${rootURL}/${options.url}`,
      accessToken: await getToken()
    });
  }

  return {
    invitations: {
      async create({ organizationId, email, roles }) {
        return fetchJSON(`organizations/${organizationId}/invitations`, {
          method: "POST",
          throwHttpErrors: true,
          json: { email, roles }
        });
      }
    },
    accounts: {
      setName: (data: AccountNames) =>
        fetch.post(`accounts/me/name`, { json: data }).json<AccountNames>(),
      setNameByAdministrate: (data: AccountNamesByAdministrate) =>
        fetch
          .post(`accounts/name`, { json: data })
          .json<AccountNamesByAdministrate>(),
      getMyColors: () =>
        json<MyColors>("accounts/me/color").then(convertToColorAdjustment),
      setMyColors: (caseId: string, data: ColorAdjustment) =>
        fetch
          .post("accounts/me/color", {
            json: { ...convertToMyColors(data), caseId }
          })
          .json<MyColors>()
          .then(convertToColorAdjustment),
      getMenuList: () => json<Medmain.MenuList>("accounts/me/menu")
    },
    colors: {
      getList: (organizationId: string) =>
        json<Medmain.ColorSetting[]>(`organizations/${organizationId}/colors`),
      get: (organizationId: string, colorSettingId: string) =>
        json<Medmain.ColorSetting>(
          `organizations/${organizationId}/colors/${colorSettingId}`
        ),
      create: (organizationId: string, form: ColorSettingFormData) =>
        fetch
          .post(`organizations/${organizationId}/colors`, {
            json: form
          })
          .json<Medmain.ColorSetting>(),
      update: (
        organizationId: string,
        colorSettingId: string,
        form: ColorSettingFormData
      ) =>
        patchJson<Medmain.ColorSetting>(
          `organizations/${organizationId}/colors/${colorSettingId}`,
          { json: form }
        ),
      delete: (organizationId: string, colorSettingId: string) =>
        fetch.delete(`organizations/${organizationId}/colors/${colorSettingId}`)
    },
    cases: {
      get({ caseId }: { caseId: string }): Promise<Medmain.Case | undefined> {
        return json<Medmain.Case>(`cases/${caseId}`);
      },
      async find(searchOptions: SearchOptions) {
        return fetchJSON(`cases/search`, {
          method: "POST",
          json: serializeSearchOptions(searchOptions),
          timeout: false // TODO: set a reasonable limit when the API is faster
        });
      },
      async create(caseData: CaseFormData) {
        return fetchJSON(`cases`, {
          method: "POST",
          json: caseData,
          throwHttpErrors: false
        });
      },
      async update(caseId: string, caseData: CaseFormData) {
        const data = await makeRequestMergePatchJSON(`cases/${caseId}`, {
          json: caseData,
          throwHttpErrors: false
        });
        return parseResponseToJSON(data);
      },
      delete({
        caseId,
        recursive
      }: {
        caseId: Medmain.Case["id"];
        recursive: boolean;
      }) {
        const queryString = stringify({ recursive });
        return fetch.delete(`cases/${caseId}?${queryString}`);
      },
      async transferOwnership(
        caseId: Medmain.Case["id"],
        orgId: Medmain.Organization["id"]
      ) {
        return await fetchJSON(`cases/${caseId}/transfer/${orgId}`, {
          method: "PUT",
          throwHttpErrors: false // don't throw right away, we have to parse and extract the error `message`
        });
      },
      images: {
        async get({ imageId }) {
          const imageData = await fetchJSON(`images/${imageId}`);
          return {
            ...imageData,
            zoomableImageUrl: `${rootURL}/iiif/2/${imageId}`
          };
        },
        async upload({
          caseId,
          file,
          metaData,
          onProgress
        }: {
          caseId: string;
          file: File;
          metaData?: ImageEditableData;
          onProgress: (any) => void;
        }) {
          const accessToken = await getToken();
          const queryString = stringify({
            caseId,
            filename: file.name,
            ...metaData
          });
          const url = `${rootURL}/images?${queryString}`;
          const { id, filename } = await uploadFileByXHR({
            url,
            file,
            accessToken,
            onProgress
          });
          debug(`Image created: "${id}"`, filename);
          return { id, filename };
        }
      },
      comments: {
        find: ({
          caseId,
          ...searchOptions
        }: { caseId: Medmain.Case["id"] } & Partial<SearchOptions>) =>
          fetch
            .post(`cases/${caseId}/comments/search`, {
              json: searchOptions
            })
            .json<PaginatedItemList<Medmain.Comment>>(),
        create: ({
          caseId,
          message,
          type,
          imageId
        }: {
          caseId: Medmain.Case["id"];
          message: Medmain.Comment["message"];
          type: Medmain.Comment["type"];
          imageId: Medmain.Comment["imageId"];
        }) =>
          fetch.post(`cases/${caseId}/comments`, {
            json: { message, type, imageId }
          }),
        update: async ({
          caseId,
          commentId,
          message,
          type,
          imageId
        }: {
          caseId: Medmain.Case["id"];
          commentId: Medmain.Comment["id"];
        } & Partial<Medmain.Comment>) =>
          await patchJson(`cases/${caseId}/comments/${commentId}`, {
            json: { message, type, imageId }
          }),
        delete: ({
          caseId,
          commentId
        }: {
          caseId: Medmain.Case["id"];
          commentId: Medmain.Comment["id"];
        }) => fetch.delete(`cases/${caseId}/comments/${commentId}`)
      },
      grants: {
        list({ caseId }) {
          return json<{ data: Medmain.CaseGrant[] }>(`cases/${caseId}/grants`);
        },
        create({
          caseId,
          grantedRole,
          granteeType,
          granteeId
        }: {
          caseId: Medmain.Case["id"];
          grantedRole: Medmain.CaseGrant["grantedRole"];
          granteeType: Medmain.CaseGrant["granteeType"];
          granteeId?: Medmain.Account["id"];
        }): Promise<Medmain.CaseGrant> {
          return fetchJSON(`cases/${caseId}/grants`, {
            method: "POST",
            json: {
              grantedRole,
              granteeType,
              granteeId
            }
          });
        },
        async update({
          caseId,
          grantId,
          grantedRole
        }: {
          caseId: Medmain.Case["id"];
          grantId: Medmain.CaseGrant["id"];
          grantedRole: Medmain.CaseGrant["grantedRole"];
        }): Promise<void> {
          await makeRequestMergePatchJSON(`cases/${caseId}/grants/${grantId}`, {
            json: { grantedRole }
          });
        },
        async delete({
          caseId,
          grantId
        }: {
          caseId: Medmain.Case["id"];
          grantId: Medmain.CaseGrant["id"];
        }): Promise<void> {
          await fetch.delete(`cases/${caseId}/grants/${grantId}`); // don't return a `Response` object
        }
      },
      labelled: {
        create: ({
          caseId,
          data
        }: {
          caseId: Medmain.Case["id"];
          data: Omit<Medmain.Labelled, "id">;
        }) => fetch.post(`cases/${caseId}/labelleds`, { json: data }).json(),
        delete: ({
          caseId,
          data
        }: {
          caseId: Medmain.Case["id"];
          data: Omit<Medmain.Labelled, "id">;
        }) =>
          fetch.post(`cases/${caseId}/labelleds/delete`, { json: data }).json()
      },
      listAttachments: (id: string) =>
        json<AttachmentsListData>(`cases/${id}/attachments`),
      async getAttachment(id: string, filename: string, options?: Options) {
        const url = `cases/${id}/attachments/${encodeURI(filename)}`;
        return URL.createObjectURL(await fetch(url, options).blob());
      },
      putAttachment({
        caseId,
        file,
        onProgress
      }: {
        caseId: string;
        file: File;
        onProgress: (progress: number) => void;
      }) {
        const url = `cases/${caseId}/attachments/${encodeURI(file.name)}`;
        return upload({ file, method: "PUT", url, onProgress });
      },
      deleteAttachment: (id: string, filename: string) =>
        fetch.delete(`cases/${id}/attachments/${encodeURI(filename)}`),
      listTags: (): Promise<string[]> => fetchJSON("tags")
    },
    orgs: {
      async create(organization) {
        return fetchJSON(`organizations`, {
          method: "POST",
          throwHttpErrors: false,
          json: organization
        });
      },
      get: (id: string) => json<Medmain.Organization>(`organizations/${id}`),
      list: () => json<{ data: Medmain.Organization[] }>("organizations"),
      me: {
        memberships() {
          return fetchJSON(`organizations/me/memberships`);
        }
      },
      update: (orgId: string, orgFormData: OrganizationFormData) =>
        patchJson<Medmain.Organization>(`organizations/${orgId}`, {
          json: orgFormData
        }).catch(async error => {
          const e = await (error as ky.HTTPError).response.json();
          throw new Error(e.message || "Unexpected error");
        }),
      delete({
        organizationId,
        recursive
      }: {
        organizationId: Medmain.Organization["id"];
        recursive: boolean;
      }) {
        const queryString = stringify({ recursive });
        return fetch.delete(`organizations/${organizationId}?${queryString}`);
      },
      accounts: {
        async find({ organizationId, page = 1, limit = 10, order }) {
          return fetchJSON(
            `organizations/${organizationId}/memberships?page=${page}&limit=${limit}&field=${order[0].field}&direction=${order[0].direction}`
          );
        },
        async remove(organizationId: string, accountId: string) {
          const accountIdParam = encodeURI(accountId); // The `|` character, part of the account ID, has to be encoded in Firefox
          await makeFetchRequest(
            `organizations/${organizationId}/memberships/${accountIdParam}`,
            {
              method: "DELETE"
            }
          );
        }
      },
      grants: {
        list({ organizationId }) {
          return json<{ data: Medmain.OrgGrant[] }>(
            `organizations/${organizationId}/grants`
          );
        },
        async update({
          organizationId,
          grantId,
          grantedRole
        }: {
          organizationId: string;
          grantId: string;
          grantedRole: string;
        }): Promise<void> {
          await makeRequestMergePatchJSON(
            `organizations/${organizationId}/grants/${grantId}`,
            {
              json: { grantedRole }
            }
          );
        }
      },
      partners: {
        find({ organizationId, page = 1, limit = 10 }) {
          return fetchJSON(
            `organizations/${organizationId}/partners?page=${page}&limit=${limit}`
          );
        }
      }
    },
    contracts: {
      create: (contract: ContractFormData) =>
        fetch.post(`contracts`, { json: contract }).json<{ id: string }>(),
      invitation: (contractId: string, email: string) =>
        fetch
          .post(`contracts/${contractId}/invitations`, { json: { email } })
          .json(),
      get: (id: string) => json<Medmain.Contract>(`contracts/${id}`),
      list() {
        return fetchJSON(`contracts`);
      },
      update: (contractId: string, cntFormData: ContractFormData) =>
        patchJson(`contracts/${contractId}`, { json: cntFormData }),
      delete({
        contractId,
        recursive
      }: {
        contractId: Medmain.Contract["id"];
        recursive: boolean;
      }) {
        const queryString = stringify({ recursive });
        return fetch.delete(`contracts/${contractId}?${queryString}`);
      },
      authentications: {
        create: (contractId: string) =>
          fetch
            .post(`contracts/${contractId}/authentications`)
            .json<{ id: string }>(),
        delete: (contractId: string) =>
          fetch.delete(`contracts/${contractId}/authentications`)
      },
      accounts: {
        find: (contractId: Medmain.Contract["id"]) =>
          json<Medmain.Membership>(`contracts/${contractId}/proprietors`),
        remove: (contractId: string, accountId: string) =>
          fetch.delete(`contracts/${contractId}/proprietors/${accountId}`)
      },
      organizations: {
        find: (contractId: Medmain.Contract["id"]) =>
          json<Medmain.Organization>(`contracts/${contractId}/organizations`)
      }
    },
    images: {
      get: ({ id }: { id: string }) =>
        json<any>(`images/${id}`).then(
          data => new ImageMeta(ImageMetaSchema.parse(data))
        ),
      iiif: ({ id }: { id: string }) =>
        json<any>(`iiif/2/${id}`).then(
          data => new IIIFTileSource(IIIFTileSourceSchema.parse(data))
        ),
      getViewerRequestHeaders({ accessToken }: { accessToken: string }): any {
        return { Authorization: `Bearer ${accessToken}` };
      },
      async find(searchOptions: SearchOptions) {
        return fetchJSON(`images/search`, {
          method: "POST",
          json: serializeSearchOptions(searchOptions)
        });
      },
      getSmallImageURL(imageId) {
        return `${rootURL}/images/${imageId}/data?type=SMALL`;
      },
      getZoomableImageURL(imageId) {
        return `${rootURL}/iiif/2/${imageId}`;
      },
      async update({ imageId, data }) {
        return await makeRequestMergePatchJSON(`images/${imageId}`, {
          json: data
        });
      },
      copy: (
        imageId: Medmain.Image["id"],
        dstCaseId: Medmain.Case["id"],
        copyPrediction: boolean
      ) =>
        fetch
          .post(`images/${imageId}/copy`, {
            json: { dstCaseId, copyPrediction },
            timeout: false
          })
          .json<Medmain.Image>(),
      delete({ imageId }: { imageId: string }) {
        return fetch.delete(`images/${imageId}`); // this end-point does not return JSON data
      },
      download: async (imageId: string, options?: Options) =>
        await fetch(`images/${imageId}/data`, options).blob(),
      reprocess({ imageId }: { imageId: string }) {
        return fetch.put(`images/${imageId}/reprocess`); // this end-point does not return JSON data
      },
      labelled: {
        create: ({
          imageId,
          data
        }: {
          imageId: string;
          data: Omit<Medmain.Labelled, "id">;
        }) =>
          fetch
            .post(`images/${imageId}/labelleds`, { json: data })
            .json<Medmain.Labelled>(),
        delete: (imageId: string, labelledId: string) =>
          fetch
            .delete(`images/${imageId}/labelleds/${labelledId}`)
            .json<string>()
      }
    },
    predictions: {
      async getModels({ imageId }) {
        return await fetchJSON(`predictions/models?imageId=${imageId}`);
      },
      async create({ imageId, modelName }) {
        const createdPrediction = await fetchJSON(`predictions/request`, {
          method: "POST",
          json: { imageId, modelName }
        });
        return createdPrediction;
      },
      createBulk: (data: {
        organizationId: Medmain.Organization["id"];
        predictionImages: {
          imageId: Medmain.Image["id"];
          modelName: Medmain.Image["modelName"];
        }[];
      }) =>
        fetch
          .post("predictions/bulk/request", { json: data })
          .json<{ predictionIds: Medmain.Prediction["id"][] }>(),
      delete: (id: string) => fetch.delete(`predictions/${id}`),
      async retry(predictionId: Medmain.Prediction["id"], force = false) {
        const queryString = stringify({ force });
        const updatedPrediction = await fetchJSON(
          `predictions/${predictionId}/recover?${queryString}`,
          { method: "PUT" }
        );
        return updatedPrediction;
      },
      async get(predictionId) {
        const prediction = await fetchJSON(`predictions/${predictionId}`);
        return { prediction };
      }
    },
    batchImport: {
      async parseFiles({ organizationId, filenames }) {
        debug("Parsing files", filenames);
        return await fetchJSON(`batch/parse`, {
          method: "POST",
          json: { organizationId, filenames }
        });
      }
    },
    drawings: {
      create: (
        imageId: string,
        payload: {
          geoJson: DrawingSchema["geoJson"];
        }
      ) => fetch.post(`images/${imageId}/drawings`, { json: payload }).json(),
      update: (
        imageId: string,
        id: string,
        payload: { geoJson: DrawingSchema["geoJson"] }
      ) => patchJson(`images/${imageId}/drawings/${id}`, { json: payload }),
      get: (imageId: string, id: string) =>
        json(`images/${imageId}/drawings/${id}`),
      list: (imageId: string) =>
        json<{ total: number; data: DrawingSchema[] }>(
          `images/${imageId}/drawings`
        ),
      delete: (imageId: string, id: string) =>
        fetch.delete(`images/${imageId}/drawings/${id}`),
      bulkDelete: (imageId: string, drawingIds: string[]) =>
        fetch.post(`images/${imageId}/drawings/bulk/delete`, {
          json: { drawingIds }
        })
    },
    terms: {
      get: ({
        region = "default",
        type
      }: {
        region?: Terms["region"];
        type: Terms["licenseType"];
      }) => fetch.get(`license/latest/${region}/${type}`).json<Terms>(),
      agree: ({
        region = "default",
        type
      }: {
        region?: Terms["region"];
        type: Terms["licenseType"];
      }) => fetch.post("agreement/agree", { json: { region, type } }),
      checkAIAgreement: () =>
        fetch
          .get("agreement/check/ai")
          .json<{ type: Terms["licenseType"]; agreed: boolean }>()
    }
  };
}
