import React from "react";
import numeral from "numeral";

import common from "./common";

const englishLocale = {
  $id: "en",
  $name: "English",

  // === General ===

  supportEmail: "support@medmain.com",

  okButtonLabel: "OK",
  cancelButtonLabel: "Cancel",
  closeButtonLabel: "Close",
  newButtonLabel: "New",
  editButtonLabel: "Edit",
  saveButtonLabel: "Save",
  deleteButtonLabel: "Delete",
  continueButtonLabel: "Continue",
  backButtonLabel: "Back",
  nextButtonLabel: "Next",
  previousButtonLabel: "Previous",
  searchButtonLabel: "Search",
  clearButtonLabel: "Clear",
  resetButtonLabel: "Reset",

  optionalFieldMention: "Optional",

  firstDayOfWeek: 1, // Monday

  lastNameIsBeforeFirstName: false,

  formatFullName({ firstName, lastName }) {
    return this.lastNameIsBeforeFirstName
      ? `${lastName} ${firstName}`
      : `${firstName} ${lastName}`;
  },

  // === List component ===

  listNoItemsFound: "No items found.",

  // === Formatting ===

  formatNumber(number) {
    return typeof number === "number" && !isNaN(number)
      ? numeral(number).format("0,0.[000]")
      : "--";
  },

  formatPercentage(percentage) {
    return typeof percentage === "number" && !isNaN(percentage)
      ? numeral(percentage).format("0.0") + "%"
      : "-.-%";
  },

  formatArea(area) {
    return typeof area === "number" && !isNaN(area)
      ? numeral(area).format("0.000") + " mm²"
      : "-.--- mm²";
  },

  /*
  Format a month number (indexed to 1!), according to the current locale and the given format:
  'full' => 'January'
  'partial' => 'Jan'
  */
  formatMonth(monthNumber, { format = "full" } = {}) {
    const months = this.months[format];
    if (!months || !Array.isArray(months)) {
      throw new Error("Invalid month format for the current locale!");
    }
    const text = months[monthNumber - 1];
    if (!text) {
      throw new Error(`Invalid month number: ${monthNumber}`);
    }
    return text;
  },

  months: {
    full: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]
  },

  /*
  Format a "day of the week" number (0 means Sunday), according to a given format:
  'full' => 'Monday'
  'partial' => 'Mon'
  */
  formatDayOfWeek(day, { format = "full" } = {}) {
    const days = this.days[format];
    if (!days || !Array.isArray(days)) {
      throw new Error("Invalid day format for the current locale!");
    }
    const text = days[day];
    if (!text) {
      throw new Error(`Invalid day of the week number: ${day}`);
    }
    return text;
  },

  days: {
    full: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    partial: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  },

  formatFileSize(size) {
    const getMask = () => {
      if (size >= 1e9) {
        return "0.000"; // >1 GB => 3 decimals
      }
      if (size <= 1e6) {
        return "0"; //  <1 MB => No decimals
      }
      return "0.0"; // by default: 1 decimal
    };
    return typeof size === "number" && !isNaN(size)
      ? numeral(size).format(`${getMask()} b`)
      : "--";
  },

  //　No change calculation　& DisplayOnly
  formatGibToGB(gibSize) {
    if (typeof gibSize === "number" && !isNaN(gibSize)) {
      const decimalString = gibSize.toString().split(".")[1];
      if (decimalString && decimalString.length > 2) {
        const newValue = numeral(gibSize + 0.002).format("0.[00]");

        return newValue;
      } else {
        return gibSize;
      }
    } else {
      return "--";
    }
  }
};

export const english = {
  ...common,
  ...englishLocale,

  // === Case ===
  caseList: {
    heading: "Case List",
    images: "Images",
    attachments: "PDF",
    comments: "Comments",
    createdAt: "Created",
    publicFilter: "Only Public Cases",
    create: "Create Case"
  },

  caseTabs: {
    details: "Details",
    images: "Images",
    attachments: "Attachments",
    comments: "Comments",
    permissions: "Permissions"
  },

  caseFields: {
    caseNumber: "Case Number",
    createdAt: "Created on",
    comments: "Notes",
    diagnosis: "Diagnosis",
    displayName: "Image Name",
    grossFindings: "Gross Description",
    specimenType: {
      label: "Type of Specimen",
      options: {
        biopsy: "Biopsy",
        surgical: "Surgical",
        LBC: "LBC",
        autopsy: "Autopsy"
      }
    },
    microscopicFindings: "Microscopic Description",
    model: "AI Model",
    organ: "Organ",
    organization: "Group",
    slideProvider: "Slide Provided by",
    status: "Status",
    tags: "Tags"
  },

  caseImagesSortOptions: {
    byDate: "By Date",
    byFilename: "By Name"
  },

  caseSearch: {
    heading: "Case Search",
    tip: (
      <>
        <strong>Tip: </strong>
        Use <strong>*</strong> to ignore one or more characters.
      </>
    ),
    includeAllTags: (
      <>
        Include <strong>all</strong> the tags
      </>
    ),
    includeAnyTag: (
      <>
        Include <strong>any</strong> of the tags
      </>
    ),
    visibility: {
      label: "Visibility",
      options: {
        ALL: "All cases",
        PRIVATE: (
          <>
            Only <strong>private</strong> cases
          </>
        ),
        PUBLIC: (
          <>
            Only <strong>public</strong> cases
          </>
        )
      }
    },
    results: "Search results for",
    editButtonLabel: "Edit Search Query"
  },

  caseSort: {
    options: {
      filename: "File Name",
      createdAt: "Created"
    }
  },

  imageList: {
    thumbnail: "Preview",
    fileName: "File Name",
    dimensions: "Dimensions",
    fileSize: "File Size"
  },

  imageStatuses: {
    upload_failed: "Failed to Upload",
    uploaded: "Queued",
    processing: "Processing",
    process_failed: "Failed to Process"
  },

  // === case image ===
  caseUpload: {
    modalHeading: "Add Images",
    uploadButtonLabel: (count: number) =>
      count > 1 ? `Upload ${count} Images` : "Upload",
    retry: "Retry"
  },

  fileList: {
    name: "Name",
    size: "Size",
    date: "Modified Date"
  },

  // ===Image viewer ===
  ratioTerm: "Not for diagnostic procedures",

  // === AI model chooser ===

  aiModelChooserHeading: "Image analysis AI",
  aiModelChooserChooseButtonLabel: "Start AI analysis",
  aiModelInputModelFieldLabel: "Model",

  // === Prediction panel ===

  predictionPanelHeading: "Predictions",
  predictionTitle: "AI Prediction",

  predictionSummary: {
    status: (status: Medmain.Prediction["status"]) => {
      switch (status) {
        case "waiting":
        case "running":
          return "Requested";
        case "completed":
          return "Completed";
        case "failed":
          return "Failed";
      }
      return "?";
    },
    negativeLabel: (modelName: string) => {
      switch (modelName) {
        case "cyto-uterine-cervix":
          return "NILM";
        case "cyto-urine":
          return "Negative";
      }
      return "Non-neoplastic lesion";
    }
  },

  // === User menu ===

  userMenuAccountItemLabel: "My Account",
  userMenuSignOutItemLabel: "Log out",

  // === case comments ===
  caseComments: {
    add: {
      success: "Comment added",
      error: "Unable to create a new comment"
    },
    remove: {
      success: "The comment has been deleted"
    }
  },

  // === Case permissions tab ===
  privateCaseDescription: ({ caseNumber }) =>
    `The case ${caseNumber} is private`,
  partnerUsersHeading: "Partner Users",
  noPartnerUsers: ({ orgName }) => `${orgName} has no partner users.`,
  partnerOrgsHeading: "Partner Groups",
  noPartnerOrgs: ({ orgName }) => `${orgName} has no partner groups.`,
  partnerOrgsExplanation:
    "Partner group members can view and comment all cases.",
  editPermissionButton: "Edit",
  makePublicButton: "Make Public",
  casePermissionUpdated: "Case permission updated",
  anyUser: "Anyone on PidPort",

  // === Upload Notifications ===
  messagesTab: "Messages",
  uploadingTab: "Uploading",
  noUploadingFile: "No file is being uploaded",
  uploadStarted: "Upload started",
  uploadCompleted: "Upload completed",
  uploadFailed: "Upload failed",

  // Organizations
  orgFields: {
    name: "Name",
    description: "Description",
    country: "Country",
    phone: "Phone",
    postalCode: "Postal Code",
    address: "Address",
    contact: "Contact",
    storageLimit: "Storage Limit",
    contract: "Contract",
    predictionEnabled: {
      label: "Predictions",
      value: value => (value ? "Enabled" : "Disabled")
    },
    used: "Used",
    usedOriginal: "Original",
    usedPyramidal: "Pyramidal",
    usedAttachments: "Attachments"
  },
  orgDelete: {
    heading: "Delete Group",
    buttonLabel: "Delete",
    confirm: (orgName: string) => (
      <>
        Are you sure you want to delete <strong>{orgName}</strong>?
      </>
    ),
    success: (orgName: string) => `${orgName} has been deleted`
  },

  // === Organization accounts ===
  accountList: {
    removeButtonLabel: "Remove",
    editButtonLabel: "Edit",
    inviteButtonLabel: "Invite User",
    emailVerified: "Verified",
    emailNotVerified: "Not verified"
  },
  accountFields: {
    email: "Email",
    fullName: "Name",
    role: "Role",
    emailVerificationStatus: "Status",
    Invited: "Invited"
  },
  orgRoles: {
    read_only: "Read Only",
    member: "Member",
    administrator: "Administrator",
    proprietor: "Proprietor"
  },
  orgInviteUser: {
    heading: (orgName: string) => `Invite to ${orgName}`,
    headingByImportCSV: (orgName: string) =>
      `Invite to ${orgName} by Importing CSV`,
    buttonLabel: "Send Invitation",
    success: (email: string) => `Invitation sent to ${email}`,
    successByImportCSV: "Invitation sent CSV Import"
  },
  orgRemoveAccount: {
    heading: "Remove User",
    buttonLabel: "Remove User",
    confirm: (orgName: string, userName: string) => (
      <>
        Are you sure you want to remove <strong>{userName}</strong> from the
        group {orgName}?
      </>
    ),
    success: (userName: string) => `${userName} has been removed from the group`
  },
  orgEditAccount: {
    heading: "Edit User",
    buttonLabel: "Edit User",
    success: (userName: string) => `${userName} has been edited from the group`
  },
  unknownOrg: "Unknown org.",

  // Contracts
  cntFields: {
    name: "Name",
    contractPlan: "Plan",
    contractStartDate: "Start date",
    contractEndDate: "End date",
    contractorName: "Contractor name",
    contractDetails: "Contract details",
    countryCode: "Country code",
    postalCode: "Postal code",
    address: "Address",
    tel: "Tel",
    emergencyContact: "Emergency contact",
    billingContact: "Billing contact",
    orgCreationLimits: "Group limits",
    storageLimit: "Storage Limit(GB)",
    usedOriginal: "Original",
    usedPyramidal: "Pyramidal",
    usedAttachments: "Attachments",
    multiFactorAuthentication: "Multi Factor Authentication",
    predictionEnabled: {
      label: "Predictions",
      value: value => (value ? "Enabled" : "Disabled")
    }
  },
  cntInviteUser: {
    heading: (cntName: string) => `Invite to ${cntName}`,
    buttonLabel: "Send Invitation",
    success: (email: string) => `Invitation sent to ${email}`
  },
  cntRemoveAccount: {
    heading: "Remove User",
    buttonLabel: "Remove User",
    confirm: (cntName: string, userName: string) => (
      <>
        Are you sure you want to remove <strong>{userName}</strong> from the
        contract {cntName}?
      </>
    ),
    success: (userName: string) =>
      `${userName} has been removed from the contract`
  },
  cntEditAccount: {
    heading: "Edit User",
    buttonLabel: "Edit User",
    success: (userName: string) =>
      `${userName} has been edited from the contract`
  },
  cntDelete: {
    heading: "Delete Contract",
    buttonLabel: "Delete",
    confirm: (cntName: string) => (
      <>
        Are you sure you want to delete <strong>{cntName}</strong>?
      </>
    ),
    success: (cntName: string) => `${cntName} has been deleted`
  },
  unknownCnt: "Unknown contract.",

  // Bulk upload
  bulkUpload: {
    fileUploadErrorIntro: "Please fix the following files",
    duplicateFilenameError: "File already exists",
    uploadButtonLabel: (count: number) =>
      count > 1 ? `Upload ${count} Files` : "Upload"
  },

  // === terms ===
  terms: {
    heading: "Please agree to the terms of service and privacy policy",
    agreementTermsOfServiceLabel:
      "I agree to the terms of service and privacy policy",
    agreementTermsOfServiceError:
      "You must agree to the terms of service and privacy policy.",
    agreementButton: "Continue",
    ai: {
      heading: "Please agree to the terms of Image Analysis AI",
      agreementTermsOfAILabel: "I agree to the terms of Image Analysis AI",
      agreementTermsOfAIError:
        "You must agree to the terms of Image Analysis AI.",
      agreementButton: "Continue",
      cancelButton: "Not agree",
      closeButton: "Close"
    }
  }
};

export default english;

export type LocaleDictionary = typeof english;
