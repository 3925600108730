import React, { useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  Box,
  Icon,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  Badge,
  Stack
} from "@chakra-ui/react";
import { MdLock, MdLockOpen } from "react-icons/md";

import { useLocale } from "app/locale";
import { useAPI } from "api";
import { Button, ModalCloseButton, Radio, RadioGroup } from "components/core";

type CommonProps = {
  pathologicCase: Medmain.Case;
  role?: Medmain.CaseGrant["grantedRole"];
  grantId?: Medmain.CaseGrant["id"];
  close: (value: boolean) => void;
};
type SharePartnerProps = CommonProps & {
  granteeName: string;
  granteeId: Medmain.CaseGrant["granteeId"];
};
export const DialogShareCasePartner = (props: SharePartnerProps) => {
  const { role, granteeName, close } = props;
  const locale = useLocale();
  const { nextRole, setNextRole, isPending, error, onSubmit } = useShareCase({
    granteeType: "account",
    ...props
  });
  const defaultValue = "no_access";

  return (
    <form onSubmit={onSubmit}>
      <ModalHeader>
        {locale.todo("Share Case with ")} {granteeName}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <RadioGroup
          onChange={value => {
            setNextRole(
              value === defaultValue
                ? undefined
                : (value as Medmain.CaseGrant["grantedRole"])
            );
          }}
          value={nextRole || defaultValue}
        >
          <Stack>
            <Radio value={defaultValue}>No access</Radio>
            <Radio value="reader">Can view and comment</Radio>
            <Radio value="owner">
              Can view, comment, edit, and delete
              <Badge variant="outline" colorScheme="green" ml={2}>
                Full Access
              </Badge>
            </Radio>
          </Stack>
        </RadioGroup>
        {error && <ErrorMessage error={error} />}
      </ModalBody>
      <Footer
        isPending={isPending}
        isDisabled={role === nextRole}
        close={close}
      />
    </form>
  );
};

export const DialogShareCasePublic = (props: CommonProps) => {
  const { pathologicCase, role, close } = props;
  const locale = useLocale();
  const { nextRole, setNextRole, isPending, error, onSubmit } = useShareCase({
    granteeType: "all",
    ...props
  });
  const defaultValue = "private";

  return (
    <form onSubmit={onSubmit}>
      <ModalHeader>
        {locale.todo("Share Case")}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <PublicAccessInfo
          pathologicCase={pathologicCase}
          sharingStatus={role}
          mb={4}
        />
        <RadioGroup
          onChange={value => {
            setNextRole(
              value === defaultValue
                ? undefined
                : (value as Medmain.CaseGrant["grantedRole"])
            );
          }}
          value={nextRole || defaultValue}
        >
          <Stack>
            <Radio value={defaultValue}>Private</Radio>
            <Radio value="reader">Anyone on PidPort can view</Radio>
            <Radio value="owner">Anyone on PidPort can edit and comment</Radio>
          </Stack>
        </RadioGroup>
        {error && <ErrorMessage error={error} />}
      </ModalBody>
      <Footer
        isPending={isPending}
        isDisabled={role === nextRole}
        close={close}
      />
    </form>
  );
};

const ErrorMessage = ({ error }) => {
  const locale = useLocale();
  return (
    <Alert status="error" mt={4}>
      <AlertIcon />
      <AlertDescription>
        <Box>{locale.todo("Unable to share the case")}</Box>
        {error.message && <Box>{error.message}</Box>}
      </AlertDescription>
    </Alert>
  );
};

const Footer = ({ isPending, isDisabled, close }) => {
  const locale = useLocale();
  return (
    <ModalFooter>
      <ButtonGroup>
        <Button onClick={() => close(false)} isDisabled={isPending}>
          {locale.todo("Cancel")}
        </Button>
        <Button
          type="submit"
          primary
          isLoading={isPending}
          isDisabled={isDisabled}
        >
          {locale.todo("Save")}
        </Button>
      </ButtonGroup>
    </ModalFooter>
  );
};

const PublicAccessInfo = ({
  sharingStatus,
  pathologicCase,
  ...props
}: {
  sharingStatus?: Medmain.CaseGrant["grantedRole"];
  pathologicCase: Medmain.Case;
} & AlertProps) => {
  const { caseNumber } = pathologicCase;

  if (!sharingStatus) {
    return (
      <Alert status="info" bg="primary.50" {...props}>
        <Icon as={MdLock} boxSize="24px" mr={2} color="primary.300" />
        <AlertDescription>
          The case <i>{caseNumber}</i> is private.
        </AlertDescription>
      </Alert>
    );
  }
  return (
    <Alert status="warning" fontSize="sm" {...props}>
      <AlertIcon as={MdLockOpen} boxSize="24px" mr={2} />
      <AlertDescription>
        The case <i>{caseNumber}</i> is public.
      </AlertDescription>
    </Alert>
  );
};

type PartnerHookProps = SharePartnerProps & {
  granteeType: "account";
};
type PublicHookProps = CommonProps & { granteeType: "all" };
type HookProps = PartnerHookProps | PublicHookProps;
function useShareCase(props: HookProps) {
  const { pathologicCase, grantId, role, close, ...otherProps } = props;
  const api = useAPI();
  const [isPending, setIsPending] = useState(false);
  const [nextRole, setNextRole] = useState<
    Medmain.CaseGrant["grantedRole"] | undefined
  >(role);
  const [error, setError] = useState<Error>();
  const { id: caseId } = pathologicCase;

  const onSubmit = async event => {
    event.preventDefault();
    setIsPending(true);
    try {
      await callGrantAPI();
      close(true);
    } catch (error) {
      setError(error);
      setIsPending(false);
    }
  };

  function callGrantAPI() {
    if (!role) {
      return api.cases.grants.create({
        caseId,
        grantedRole: nextRole as Medmain.CaseGrant["grantedRole"],
        ...otherProps // includes `grantedType` and `granteeId` if available
      });
    }

    if (!nextRole) {
      return api.cases.grants.delete({ caseId, grantId: grantId! });
    }

    return api.cases.grants.update({
      caseId,
      grantId: grantId!,
      grantedRole: nextRole
    });
  }

  return {
    nextRole,
    setNextRole,
    isPending,
    setIsPending,
    error,
    setError,
    onSubmit
  };
}
