import { Checkbox as ChakraCheckbox } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const Checkbox = styled(ChakraCheckbox)`
  ${props => `
    span {
      &[data-checked],
      &:hover:not(:disabled) {
        color: ${props.theme.colors.gray["900"]};
        background-color: white;
        border-color: ${props.theme.colors.gray["300"]};
      }
    }
  `}
`;
