import {
  Button as ChakraButton,
  ButtonProps,
  IconButton as ChakraIconButton,
  IconButtonProps,
  MenuButton as ChakraMenuButton
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { usePersistSearchParams } from "app/persist-search-params";
import { BackIcon } from "./icons";

const StyledButton = styled(ChakraButton)`
  color: ${props => props.theme.colors.gray["900"]};
  background-color: ${props => props.theme.colors.gray["200"]};
  &:disabled {
    border-color: ${props => props.theme.colors.gray["300"]};
    opacity: 0.4;
  }
  &:hover:not(:disabled) {
    background-color: ${props => props.theme.colors.gray["400"]};
    border-color: ${props => props.theme.colors.gray["300"]};
  }
  &:active:not(:disabled) {
    background-color: ${props => props.theme.colors.gray["500"]};
    border-color: ${props => props.theme.colors.gray["300"]};
  }
`;

interface Props extends ButtonProps {
  primary?: boolean;
}

// Medmain custom Button: either a purple button or a white button for secondary actions
export const Button = ({ primary, ...props }: Props) => {
  // For primary, ensure colorScheme set to "primary".
  if (primary) {
    return (
      <ChakraButton
        color="gray.900"
        {...props}
        colorScheme="primary"
        borderRadius="2px"
      />
    );
  }
  // For other situation, variant defaults to "outline", yet overrideable.
  return (
    <StyledButton
      {...props}
      variant={props.variant || "outline"}
      borderRadius="2px"
    />
  );
};

const StyledInfoButton = styled(ChakraIconButton)`
  ${props =>
    props.isOpen
      ? `
      border-color: ${props.theme.colors.primary700Alpha(0.0)};
      color: ${props.theme.colors.gray["900"]};
      background-color: ${props.theme.colors.primary500Alpha(0.2)};
      &:focus {
        box-shadow: none;
      }
      &:disabled {
        border-color: ${props.theme.colors.primary["300"]};
        opacity: 0.4;
      }
      &:hover:not(:disabled) {
        border-color: ${props.theme.colors.primary700Alpha(0.5)};
        background-color: ${props.theme.colors.primary500Alpha(0.2)};
      }
      &:active:not(:disabled) {
        border-color: ${props.theme.colors.primary700Alpha(0.5)};
        background-color: ${props.theme.colors.primary500Alpha(0.5)};
      }
    `
      : `
      border-color: ${props.theme.colors.primary700Alpha(0.0)};
      color: ${props.theme.colors.gray["900"]};
      background-color: ${props.theme.colors.gray["200"]};
      &:focus {
        box-shadow: none;
      }
      &:disabled {
        border-color: ${props.theme.colors.gray["300"]};
        opacity: 0.4;
      }
      &:hover:not(:disabled) {
        border-color: ${props.theme.colors.primary700Alpha(0.5)};
        background-color: ${props.theme.colors.gray["400"]};
      }
      &:active:not(:disabled) {
        border-color: ${props.theme.colors.primary700Alpha(0.5)};
        background-color: ${props.theme.colors.gray["500"]};
      }
    `}
`;

interface InfoButtonProps extends IconButtonProps {
  isOpen?: boolean;
}

export const InfoButton = (props: InfoButtonProps) => {
  return <StyledInfoButton {...props} variant={props.variant || "outline"} />;
};

const StyledIconButton = styled(ChakraIconButton)`
  color: ${props => props.theme.colors.gray["900"]};
  background-color: ${props => props.theme.colors.gray["200"]};
  &:disabled {
    border-color: ${props => props.theme.colors.gray["300"]};
    opacity: 0.4;
  }
  &:hover:not(:disabled) {
    background-color: ${props => props.theme.colors.gray["400"]};
  }
  &:active:not(:disabled) {
    background-color: ${props => props.theme.colors.gray["500"]};
  }
`;

export const IconButton = (props: IconButtonProps) => {
  return (
    <StyledIconButton
      {...props}
      variant={props.variant || "outline"}
      borderRadius="2px"
    />
  );
};

export const RoundBackButton = (props: IconButtonProps) => {
  return <StyledIconButton icon={<BackIcon />} isRound {...props} />;
};

// Back button used to go from an item page to the list page, reading the `from` URL parameter
export const RoundBackToListButton = (
  props: IconButtonProps & { pathname: string }
) => {
  const { showList } = usePersistSearchParams();
  const { pathname } = props;

  return (
    <StyledIconButton
      icon={<BackIcon />}
      isRound
      fontSize={"1.5rem"}
      variant="outline"
      onClick={() => showList(pathname)}
      {...props}
    />
  );
};

export const MenuButton = styled(ChakraMenuButton)`
  color: ${props => props.theme.colors.gray["900"]};
  background-color: ${props => props.theme.colors.gray["200"]};
  &:disabled {
    border-color: ${props => props.theme.colors.gray["300"]};
    opacity: 0.4;
  }
  &:hover:not(:disabled) {
    background-color: ${props => props.theme.colors.gray["400"]};
  }
  &:active:not(:disabled) {
    background-color: ${props => props.theme.colors.gray["500"]};
  }
`;
