import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Box, Flex, Tab, Tabs, TabList, useToast } from "@chakra-ui/react";
import { useAsync, AsyncOptions } from "react-async";
import isEqual from "lodash/isEqual";

import { useAPI } from "api";
import { useLocale } from "app/locale";
import { useAccount } from "app/auth-container";
import { useParseURL, usePagination } from "app/pagination";
import { hasPermission } from "app/permissions";
import { useSearch } from "app/search";
import { Spinner, Alert, Button, Textarea, CheckIcon } from "components/core";
import { CommentList } from "components/cases/case-comment-list-old";
import {
  PaginatedListActionBar,
  PaginationNumbers
} from "components/common/pagination";
import { OrgPicker } from "components/organizations/org-picker";
import { FetchCaseState } from "./case-item-fetch-data";
import { SearchOptions } from "types";
import { PAGINATION_MINIMUM_LIMIT } from "components/common/pagination";

export const CaseComments = ({
  pathologicCase,
  setNumberOfComments
}: Pick<FetchCaseState, "pathologicCase"> & {
  setNumberOfComments: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const locale = useLocale();
  const api = useAPI();
  const toast = useToast();

  const caseId = pathologicCase.id;

  const searchOptions = useParseURL({
    limit: PAGINATION_MINIMUM_LIMIT,
    order: orderOptions[0]
  });

  const { data, isPending: isFetching, error, reload } = useFetchComments({
    caseId,
    ...searchOptions
  });
  if (!isFetching) {
    setNumberOfComments(data?.data?.length ?? 0);
  }

  const { run: createComment, isPending: isSaving } = useCreateComment({
    caseId,
    onResolve: () => {
      toast({
        description: locale.caseComments.add.success,
        status: "success",
        isClosable: true
      });
      reload();
    },
    onReject: () =>
      toast({
        description: locale.caseComments.add.error,
        status: "error",
        isClosable: true
      })
  });

  const canAddComment = hasPermission("case/create_comment", pathologicCase);

  return (
    <>
      <Box>
        <PaginatedCommentList
          caseId={caseId}
          comments={data?.data}
          isFetching={isFetching}
          error={error}
          onUpdate={async ({ message, id }) => {
            await api.cases.comments.update({
              caseId,
              commentId: id,
              message
            });
            reload();
          }}
          onDelete={async commentId => {
            await api.cases.comments.delete({ caseId, commentId });
          }}
          onDeleteSuccess={() => {
            toast({
              description: locale.caseComments.remove.success,
              isClosable: true
            });
            reload();
          }}
          searchOptions={searchOptions}
          total={data?.total}
        />
      </Box>
      {!isFetching && canAddComment && (
        <Box flexShrink={0}>
          <AddCommentForm
            onAdd={createComment}
            caseOrgId={pathologicCase.organizationId}
            isPending={isSaving}
          />
        </Box>
      )}
    </>
  );
};

const AddCommentForm = ({ onAdd, isPending, caseOrgId }) => {
  const { visibleOrgs } = useAccount();
  const [comment, setComment] = useState("");

  const orgs = visibleOrgs.filter(org =>
    hasPermission("org/create_comment", org)
  );
  const userBelongsToCaseOrg = orgs.map(org => org.id).includes(caseOrgId);
  // The user has to pick an org if she/he does not belong to the case org (#238)
  const showOrgPicker = orgs.length > 1 && !userBelongsToCaseOrg;

  const getDefaultOrgId = () => {
    if (orgs.length === 1) return orgs[0].id;
    return userBelongsToCaseOrg ? caseOrgId : "";
  };

  const [orgId, setOrgId] = useState(getDefaultOrgId());

  const onSubmit = event => {
    event.preventDefault();
    onAdd({ message: comment, authorOrganizationId: orgId });
  };
  const onChange = event => {
    setComment(event.target.value);
  };
  const canSubmit = comment.length > 0 && !!orgId;

  return (
    <Box borderWidth="1px">
      <Box backgroundColor="gray.50" px={4} py={3} fontWeight={600}>
        Add a New Comment
      </Box>
      <Box as="form" onSubmit={onSubmit} p="4">
        {showOrgPicker && (
          <Box mb={4}>
            <OrgPicker
              onChange={setOrgId}
              defaultOrgId={orgId}
              permissionType="org/create_comment"
            />
          </Box>
        )}
        <Textarea
          height="150px"
          value={comment}
          onChange={onChange}
          isDisabled={isPending}
          placeholder="Enter a new comment"
        ></Textarea>
        <Flex mt={4} justifyContent="flex-end">
          <Button
            primary
            leftIcon={<CheckIcon />}
            type="submit"
            isLoading={isPending}
            isDisabled={!canSubmit}
          >
            Save
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

const PaginatedCommentList = ({
  caseId,
  comments,
  isFetching,
  error,
  onUpdate,
  onDelete,
  onDeleteSuccess,
  searchOptions,
  total
}) => {
  const locale = useLocale();
  const history = useHistory();
  const { offset } = usePagination({ searchOptions, total });
  const { toggleSortOrder } = useSearch(searchOptions);
  const { limit, order: currentOrder } = searchOptions;

  const currentOrderOptionIndex = orderOptions.findIndex(order =>
    isEqual(order, currentOrder)
  );

  if (isFetching) {
    return (
      <Flex minH="250px" align="center" justify="center" borderWidth="1px">
        <Spinner />
      </Flex>
    );
  }

  if (error) {
    return (
      <Alert status="error" mb={4}>
        {locale.todo("Unable to load the comments")} {error.message}
      </Alert>
    );
  }

  if (!comments.length) {
    return (
      <Box
        mb={6}
        p={4}
        backgroundColor="gray.50"
        borderWidth="1px"
        borderRadius="4px"
        color="gray.600"
        fontStyle="italic"
      >
        {locale.todo("No comments for this case")}
      </Box>
    );
  }

  return (
    <Box mb={6}>
      <Flex mb={4} align="center">
        <Box flexGrow={1}>
          {total === 1 ? "One comment" : `${total} comments`}
          {total > limit && (
            <Box
              as="span"
              fontSize="md"
              fontWeight={500}
              color="gray.500"
              ml={3}
            >
              <PaginationNumbers total={total} limit={limit} offset={offset} />
            </Box>
          )}
        </Box>
        {total > 1 && (
          <Box p={1}>
            <Tabs
              index={currentOrderOptionIndex}
              onChange={index => {
                const nextLocation = toggleSortOrder("createdAt");
                history.push(nextLocation);
              }}
              colorScheme="purple"
              variant="soft-rounded"
              size="sm"
            >
              <TabList>
                <Tab>{locale.todo("Oldest First")}</Tab>
                <Tab>{locale.todo("Newest First")}</Tab>
              </TabList>
            </Tabs>
          </Box>
        )}
      </Flex>
      <CommentList
        caseId={caseId}
        comments={comments}
        onUpdate={onUpdate}
        onDeleteSuccess={onDeleteSuccess}
      />
      <PaginatedListActionBar searchOptions={searchOptions} total={total} />
    </Box>
  );
};

const orderOptions = [
  [{ field: "createdAt", direction: "ASC" }],
  [{ field: "createdAt", direction: "DESC" }]
];

type FetchCommentsParams = { caseId: string } & SearchOptions;
function useFetchComments({ caseId, ...searchOptions }: FetchCommentsParams) {
  const { limit, order, page } = searchOptions;
  const { field, direction } = order[0];
  const api = useAPI();
  // TODO: refactor to remove `useCallback` (removing the need of this hook) without breaking the pagination!
  const loadComments = useCallback(() => {
    return api.cases.comments.find({
      caseId,
      page,
      limit,
      order: [
        {
          field: field,
          direction: direction
        }
      ]
    });
  }, [api.cases.comments, caseId, direction, field, page, limit]);
  return useAsync(loadComments);
}

type CreateCommentOptions = {
  caseId: string;
} & AsyncOptions<void>;
function useCreateComment({ caseId, ...props }: CreateCommentOptions) {
  const api = useAPI();
  const addComment = async ([values]: any[]) => {
    const { message } = values;
    const type = "case";
    await api.cases.comments.create({
      caseId,
      type,
      message,
      imageId: undefined
    });
  };

  return useAsync({ deferFn: addComment, ...props });
}
