import {
  Button as ChakraButton,
  ButtonProps,
  IconButton as ChakraIconButton,
  IconButtonProps
} from "@chakra-ui/react";
import styled from "@emotion/styled";

const StyledToolbarButton = styled(ChakraButton)`
  ${props => `
    color: ${props.theme.colors.gray250Alpha(1)};
    background-color: ${props.theme.colors.gray["950"]};
    &:focus {
      box-shadow: none;
    }
    &:disabled {
      color: ${props.theme.colors.gray250Alpha(0.3)};
      background-color: ${props => props.theme.colors.gray["970"]};
    }
    &:hover:not(:disabled) {
      background-color: ${props.theme.colors.gray["850"]};
    }
    &:active:not(:disabled) {
      background-color: ${props.theme.colors.gray["960"]};
    }
    &[data-active] {
      background-color: ${props.theme.colors.gray["650"]};
    }
  `}
`;

export const ImageViewerToolbarButton = ({ ...props }: ButtonProps) => {
  return (
    <StyledToolbarButton
      {...props}
      variant={props.variant || "outline"}
      borderRadius="2px"
    />
  );
};

const StyledToolbarIconButton = styled(ChakraIconButton)`
  ${props => `
    color: ${props.theme.colors.gray250Alpha(1)};
    background-color: ${props.theme.colors.gray["950"]};
    &:focus {
      box-shadow: none;
    }
    &:disabled {
      color: ${props.theme.colors.gray250Alpha(0.3)};
      background-color: ${props => props.theme.colors.gray["970"]};
    }
    &:hover:not(:disabled) {
      background-color: ${props.theme.colors.gray["850"]};
    }
    &:active:not(:disabled) {
      background-color: ${props.theme.colors.gray["960"]};
    }
    &[data-active] {
      background-color: ${props.theme.colors.gray["650"]};
    }
  `}
`;

export const ImageViewerToolbarIconButton = (props: IconButtonProps) => {
  return (
    <StyledToolbarIconButton
      {...props}
      variant={props.variant || "outline"}
      borderRadius="2px"
    />
  );
};

const StyledButton = styled(ChakraButton)`
  &:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.gray["650"]};
  }
`;

interface ImageViewerButtonProps extends ButtonProps {
  addBoxShadow?: boolean;
}

export const ImageViewerButton = ({
  addBoxShadow,
  ...props
}: ImageViewerButtonProps) => {
  if (addBoxShadow) {
    return (
      <StyledButton
        {...props}
        variant={props.variant || "outline"}
        borderRadius="2px"
      />
    );
  }
  return (
    <ChakraButton
      {...props}
      variant={props.variant || "outline"}
      borderRadius="2px"
    />
  );
};

export const ImageViewerIconButton = (props: IconButtonProps) => {
  return (
    <ChakraIconButton
      {...props}
      variant={props.variant || "outline"}
      borderRadius="2px"
    />
  );
};
