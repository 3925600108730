import React from "react";
import { Box, Icon } from "@chakra-ui/react";
import { MdPeople } from "react-icons/md";
import styled from "@emotion/styled";

import { useLocale } from "app/locale";
import { getAccountDisplayName } from "models/account";
import { Avatar, Table } from "components/core";
import { NameNotSet } from "./organization-account-list";

type Props = {
  partners: Medmain.Partner[];
};
export const PartnerList = ({ partners }: Props) => {
  const locale = useLocale();
  const columns = [
    {
      width: 32,
      render: (text, record) => {
        const { type, partner } = record;
        const { avatarUrl } = partner;
        if (type === "organization") {
          return <Icon as={MdPeople} boxSize="32px" color="gray.500" />;
        }
        return (
          <Avatar
            name={getAccountDisplayName(partner, locale)}
            size="sm"
            src={avatarUrl}
          />
        );
      }
    },
    {
      title: "Email",
      width: 250,
      render: (text, record) => {
        if (record.type === "organization") {
          return record.partner.name;
        }
        return record.partner.email;
      }
    },
    {
      title: "Name",
      render: (text, record) => {
        if (record.type === "organization") return null;
        const fullName = locale.formatFullName(record.partner);
        if (!fullName) return <NameNotSet />;
        return fullName;
      }
    }
  ];
  return (
    <TableContainer>
      <Table
        dataSource={partners}
        columns={columns}
        rowKey={record => record.id}
        bordered={false}
        showHeader={false}
        pagination={false}
        shouldShowHoverBackground={false}
        size="small"
      />
    </TableContainer>
  );
};

const TableContainer = styled(Box)``;
