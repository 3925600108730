import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

/*
A generic component used to display the initial empty state
Similar to GitHub "blankslate": https://primer.style/css/components/blankslate
*/
export const EmptyContent = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      py="5rem"
      borderWidth="1px"
      borderRadius="4px"
      textAlign="center"
      color="gray.500"
      {...props}
    >
      {children}
    </Box>
  );
};
