import { ColumnsType } from "antd/es/table";
import { MdPeople, MdShare } from "react-icons/md";
import { Box, Icon, Stack, useBreakpointValue } from "@chakra-ui/react";
import styled from "@emotion/styled";

import { useLocale } from "app/locale";
import { getAccountDisplayName } from "models/account";
import { Avatar, EditIcon, IconButton, Table } from "components/core";

type PartnerWithGrant = {
  id: Medmain.AccountPartner["id"];
  grantId?: Medmain.CaseGrant["id"];
  grantedRole?: Medmain.CaseGrant["grantedRole"];
} & Medmain.AccountPartner["partner"];

type Props = {
  partners: PartnerWithGrant[];
  onChange?: (partner: PartnerWithGrant) => void;
};
export const CaseAccessControlList = ({ partners, onChange }: Props) => {
  const device = useBreakpointValue({
    base: "pc",
    sm: "sp",
    md: "tablet",
    pc: "pc"
  });
  const locale = useLocale();
  const columns = [
    {
      width: 28,
      render: (text, record) => {
        const { type, avatarUrl } = record;
        if (type === "account") {
          return (
            <Avatar
              size="sm"
              name={getAccountDisplayName(record, locale)}
              src={avatarUrl}
            />
          );
        }
        return <Icon as={MdPeople} boxSize="48px" color="gray.500" />;
      }
    },
    device === "sp" && {
      title: "Name",
      width: 200,
      render: (text, record) => {
        if (record.type === "organization") {
          return (
            <Stack>
              <Box wordBreak="break-word">{record.name}</Box>
              <DisplayRole role={record.grantedRole} />
            </Stack>
          );
        }
        return (
          <Stack>
            <Box wordBreak="break-word">
              {getAccountDisplayName(record, locale)}
            </Box>
            <DisplayRole role={record.grantedRole} />
          </Stack>
        );
      }
    },
    device !== "sp" && {
      title: "Name",
      width: 200,
      render: (text, record) => {
        if (record.type === "organization") {
          return record.name;
        }
        return getAccountDisplayName(record, locale);
      }
    },
    device !== "sp" && {
      title: "Access",
      render: (text, record) => {
        return <DisplayRole role={record.grantedRole} />;
      }
    },
    {
      title: "",
      className: "cell-actions",
      width: 40,
      render: (text, record) => {
        const icon = record.grantedRole ? <EditIcon /> : <MdShare />;
        const buttonLabel = record.grantedRole
          ? locale.todo("Edit")
          : locale.todo("Share");
        return (
          <IconButton
            onClick={onChange ? () => onChange(record) : undefined}
            icon={icon}
            isDisabled={!onChange}
            aria-label={buttonLabel}
          />
        );
      }
    }
  ].filter(value => !!value) as ColumnsType<PartnerWithGrant>;
  return (
    <TableContainer>
      <Table
        dataSource={partners}
        columns={columns}
        rowKey={record => record.id}
        bordered={false}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </TableContainer>
  );
};

export const DisplayRole = ({ role }) => {
  const locale = useLocale();
  switch (role) {
    case "owner":
      return <>{locale.todo("can view, comment, edit, and delete")}</>;
    case "reader":
      return <>{locale.todo("can view and comment")}</>;
    default:
      return (
        <Box as="span" color="mutedTextColor" fontStyle="italic">
          {locale.todo("Not shared")}
        </Box>
      );
  }
};

const TableContainer = styled(Box)`
  button:hover {
    border-color: ${props => props.theme.colors.primary["300"]};
    color: ${props => props.theme.colors.primary["500"]};
    background-color: white;
  }
`;
