import React from "react";
import {
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { ColumnsType } from "antd/es/table";
import { MdHourglassEmpty, MdMoreHoriz } from "react-icons/md";
import { AiOutlineUserDelete } from "react-icons/ai";
import { LocationDescriptorObject } from "history";
import { useHistory } from "react-router-dom";

import { useLocale } from "app/locale";
import { useRoles } from "app/roles";
import { EditIcon, CheckIcon, Table, DateTime } from "components/core";
import { SortOrderDirection } from "types";

type Props = {
  accounts: Medmain.Membership[];
  onEdit?: (account: any) => void;
  onRemove?: (account: any) => void;
  orderBy: string;
  orderDirection: SortOrderDirection;
  setSortOrder: (
    field: string,
    direction: SortOrderDirection | null
  ) => LocationDescriptorObject;
};
export const OrganizationAccountList = ({
  accounts,
  onEdit,
  onRemove,
  setSortOrder,
  orderBy,
  orderDirection
}: Props) => {
  const locale = useLocale();
  const { getRoleLabel } = useRoles();
  const history = useHistory();
  const columns: ColumnsType<Medmain.Membership> = [
    {
      width: "288px",
      title: locale.accountFields.email,
      render: (_, { profile }) => profile.email
    },
    {
      width: "145px",
      title: locale.accountFields.emailVerificationStatus,
      dataIndex: "verified",
      render: verified => {
        return verified ? (
          <Flex alignItems="center">
            <CheckIcon color="green.500" mr={2} />
            Verified
          </Flex>
        ) : (
          <Flex alignItems="center">
            <Icon as={MdHourglassEmpty} color="gray.500" mr={2} />
            Not verified
          </Flex>
        );
      }
    },
    {
      width: "207px",
      title: locale.accountFields.fullName,
      render: (_, { profile }) => {
        if (!profile.lastName || !profile.firstName) return <NameNotSet />;
        return locale.formatFullName(profile);
      }
    },
    {
      width: "127px",
      title: locale.accountFields.role,
      dataIndex: "roles",
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"], // "to prevent sorter back to default status." as mentioned here https://ant.design/components/table/
      sortOrder: getColumnSortOrder("roles")(orderBy, orderDirection),
      render: (_, { roles }) => {
        return roles.map(getRoleLabel).join(",");
      }
    },
    {
      width: "126px",
      title: locale.accountFields.Invited,
      dataIndex: "createdAt",
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"], // "to prevent sorter back to default status." as mentioned here https://ant.design/components/table/
      sortOrder: getColumnSortOrder("createdAt")(orderBy, orderDirection),
      render: createdAt => {
        return <DateTime date={new Date(createdAt)} mask="yyyy-MM-dd" />;
      }
    },
    {
      width: "79px",
      render: (_, account) => {
        if (account.roles.includes("proprietor") || !onRemove || !onEdit)
          return <Box color="gray.500">-</Box>;
        return (
          <Center>
            <Menu isLazy>
              <MenuButton
                id="organization-options"
                as={IconButton}
                variant="ghost"
                boxSize="40px"
                data-testid="organization-options"
                icon={<Icon as={MdMoreHoriz} boxSize="28px" />}
                borderRadius="99px"
              />
              <MenuList minWidth="auto">
                <MenuItem
                  icon={<Box as={EditIcon} />}
                  onClick={() => onEdit(account)}
                >
                  {locale.accountList.editButtonLabel}
                </MenuItem>
                <MenuItem
                  icon={<Box as={AiOutlineUserDelete} />}
                  onClick={() => onRemove(account)}
                >
                  {locale.accountList.removeButtonLabel}
                </MenuItem>
              </MenuList>
            </Menu>
          </Center>
        );
      }
    }
  ];

  const onChange = (pagination, filters, sorter) => {
    const orderDirections = {
      ascend: "ASC",
      descend: "DESC"
    };
    const order: SortOrderDirection = orderDirections[sorter.order] || null;
    const nextLocation = setSortOrder(sorter.field, order);
    history.push(nextLocation);
  };
  return (
    <Wrapper paddingX={0}>
      <Box>
        <Table
          rowKey={account => account.profile.email}
          columns={columns}
          dataSource={accounts}
          size="small"
          onChange={onChange}
          pagination={false}
          shouldShowHoverBackground={true}
          tableLayout="fixed"
        />
      </Box>
    </Wrapper>
  );
};
const Wrapper = styled(Box)`
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  & .ant-table-content {
    min-height: 210px;
  }
`;

export const NameNotSet = () => {
  const locale = useLocale();

  return (
    <Box as="span" color="mutedTextColor" fontStyle="italic">
      {locale.todo("Name not set")}
    </Box>
  );
};

const getColumnSortOrder = (columnKey: string) => (
  orderBy: string,
  orderDirection: SortOrderDirection
) => {
  if (columnKey !== orderBy) return null;
  if (orderDirection === "ASC") return "ascend";
  if (orderDirection === "DESC") return "descend";
  return null;
};
