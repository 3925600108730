import ky, { ResponsePromise } from "ky";
import flatten from "lodash/flatten";
import orderBy from "lodash/orderBy";
import debugModule from "debug";

import { createValidationError } from "./api-error";
import { ApiClientReturn } from "./create-api-client";
import { CaseFormData } from "components/cases/case-form";
import {
  IIIFTileSource,
  IIIFTileSourceSchema
} from "components/viewer/layers/iiif-tile-source/iiif-tile-source";
import { ImageMeta, ImageMetaSchema } from "models/image-meta";
import { SearchOptions } from "types";

const debug = debugModule("medmain:api:mock");

// Types for the api client
type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (...args: infer A) => infer R
    ? R extends ResponsePromise
      ? (...args: A) => any
      : T[P]
    : RecursivePartial<T[P]>;
};

/*
Creating the mock API used for development only
We fetch data from JSON files served from the `public` folder
 */
export function createMockAPIClient(): RecursivePartial<ApiClientReturn> {
  const rootURL = "/mock-data";

  async function makeRequest<T>(url, options = {}) {
    await wait(500);
    url = `${rootURL}/${url}`;
    const result = await ky(url).json<T>();
    debug(`Mock API request "${url}"`, options, "=>", result);
    return result;
  }

  const findCases = () => makeRequest<{ data: Medmain.Case[] }>("cases.json");

  const findOrgs = () =>
    makeRequest<{ data: Medmain.Organization[] }>("orgs.json");

  const findGrants = (): ReturnType<ApiClientReturn["cases"]["grants"]["list"]> =>
    makeRequest("grants.json");

  const findImages = async () => {
    const { data: cases } = await findCases();
    const images = flatten(cases.map(({ images }) => images));
    return images;
  };

  const findPredictions = () =>
    makeRequest("predictions.json") as Promise<Medmain.Prediction[]>;

  const getPredictionByImageId = async (imageId: string) => {
    const predictions = await findPredictions();
    return (
      predictions.find(prediction => prediction.imageId === imageId) || null
    );
  };

  const getImage = async (imageId: string) => {
    const images = await findImages();
    const foundImage = images.find(({ id }) => id === imageId) || images[0];
    const prediction = await getPredictionByImageId(foundImage.id);
    const image: Medmain.Image = { ...foundImage, prediction };
    return image;
  };

  const findComments = () =>
    makeRequest("comments.json") as Promise<Medmain.Comment[]>;

  const findPartners = () =>
    makeRequest("partners.json") as Promise<Medmain.Partner[]>;

  const findMemberships = () =>
    makeRequest("memberships.json") as Promise<Medmain.Membership[]>;

  const getOrgById = async organizationId => {
    const result = await findOrgs();
    const foundOrg = result.data.find(org => org.id === organizationId);
    if (!foundOrg)
      throw new Error(`No organization with the id ${organizationId}`);
    return foundOrg;
  };

  const paginate = ({
    page = 1,
    limit = 5,
    order,
    query
  }: any) => async fetchFn => {
    const result = await fetchFn();
    const start = (page - 1) * limit;
    const end = start + limit;
    const data = result.data || result; // some mock file only contain array of items, without `data` object

    const filteredData = filterSearchResults(data, query);

    const sort = items => {
      if (!order?.[0]?.direction) {
        return items;
      }
      const { field, direction } = order[0];
      return orderBy(items, [field], [direction.toLowerCase()]); // Lodash orderBy expect "desc" and "asc" parameters
    };

    return {
      total: filteredData.length,
      page,
      data: sort(filteredData).slice(start, end)
    };
  };

  const filterSearchResults = (items, query) => {
    if (!query || query.length === 0) return items;
    return items.filter(item => {
      return query
        .filter(({ value }) => !!value && value !== "*")
        .every(({ field, value }) => item[field] === value);
    });
  };

  return {
    invitations: {
      async create({ organizationId, email, roles }) {
        debug("Send an invitation", organizationId, email, roles);
        await wait();
        throwRandomError();
        return true;
      }
    },
    cases: {
      async get({ caseId }: { caseId: string }) {
        const { data: cases } = await findCases();
        const foundCase = cases.find(({ id }) => id.toString() === caseId);
        if (!foundCase) throw new Error(`No case with the id ${caseId}`);
        return foundCase;
      },
      async find(options) {
        const cases = await paginate(options)(findCases);
        return cases;
      },
      async create(caseData: any) {
        await wait(1000);
        // Simulate an API error about the "case number"
        if (caseData.caseNumber.length < 3) {
          throw createValidationError("API validation error", {
            caseNumber: "Invalid number"
          });
        }
        return { id: generateRandomId("case") }; // return the id of a case without images (the real API return the id of the created case)
      },
      async update(caseId: string, caseData: CaseFormData) {
        await wait(200);
        return undefined;
      },
      async delete({
        caseId,
        recursive
      }: {
        caseId: Medmain.Case["id"];
        recursive: boolean;
      }) {
        debug("Delete the case", { caseId, recursive });
        await wait(1000);
        return undefined;
      },
      async transferOwnership(caseId, orgId) {
        debug(`Transfer case "${caseId}" ownership to`, orgId);
        await wait(2000);
        return true;
      },
      grants: {
        async list({ caseId }) {
          debug("Fetching the grants", caseId);
          await wait(2000);
          const grants = await findGrants();
          return grants;
        },
        async create({ caseId, grantedRole, granteeType, granteeId }) {
          debug("Create a grant", {
            caseId,
            grantedRole,
            granteeType,
            granteeId
          });
          throwRandomError(0.5);
          return {
            id: generateRandomId("grant"),
            caseId,
            grantedRole,
            granteeType,
            granteeId
          };
        },
        async update({ caseId, grantId, grantedRole }) {
          debug("Update grant", caseId, grantId, grantedRole);
          throwRandomError(0.5);
          await wait(2000);
        },
        async delete({ caseId, grantId }) {
          debug("Delete grant", caseId, grantId);
          await wait(2000);
        }
      },
      images: {
        async get({ imageId }) {
          await wait();
          return await getImage(imageId);
        },
        async upload({ caseId, file, onProgress }) {
          debug("Simulating the upload progress", caseId, file);
          await simulateProgress(onProgress);
          return { id: "created_image_id", filename: "filename" };
        }
      },
      listAttachments: async (id: string) => {
        const attachments: Medmain.Attachment[] = [];
        return { caseId: id, attachments };
      },
      async putAttachment({
        caseId,
        file,
        onProgress
      }: {
        caseId: string;
        file: File;
        onProgress: (progress: number) => void;
      }) {
        debug("Simulating the upload progress", caseId, file);
        await simulateProgress(onProgress);
      },
      comments: {
        find: async ({
          caseId,
          ...searchOptions
        }: { caseId: string } & Partial<SearchOptions>) =>
          paginate(searchOptions)(findComments),
        async create({ caseId, message }) {
          debug("Creating a new comment", message);
          await wait(1000);
          throwRandomError(0.5);
        },
        update: async ({ caseId, commentId, message }) => {
          debug("Updating the comment", commentId, message);
          await wait(1000);
          throwRandomError(0.5);
        },
        async delete({ caseId, commentId }) {
          debug("Delete the comment", commentId);
          await wait(1000);
          throwRandomError(0.5);
        }
      },
      listTags: async () => {
        await wait(1000);
        throwRandomError(0.1);
        return ["tag-a", "tag-b", "tag-c"];
      }
    },
    images: {
      async find(options) {
        const images = await paginate(options)(findImages);
        return images;
      },
      async get({ id }) {
        const image = await getImage(id);
        return new ImageMeta(ImageMetaSchema.parse(image));
      },
      getSmallImageURL(imageId) {
        return `/mock-data/images/small/${imageId}.jpeg`; // served from the `public` folder,
      },
      iiif: ({ id }: { id: string }) =>
        fetch(getImageTileURL(id))
          .then(response => response.json())
          .then(data => {
            return new IIIFTileSource(IIIFTileSourceSchema.parse(data));
          }),
      getViewerRequestHeaders({ accessToken }: { accessToken: string }) {
        return undefined;
      },
      getZoomableImageURL(imageId) {
        return getImageTileURL(imageId);
      },
      async delete({ imageId }: { imageId: string }) {
        debug("Deleting", imageId);
        await wait(1000);
        throwRandomError(0.5);
      },
      async reprocess({ imageId }: { imageId: string }) {
        debug("Reprocessing", imageId);
        await wait(1000);
        throwRandomError(0.5);
      }
    },
    orgs: {
      async create(organization) {
        await wait(2000);
        console.log("Organization is created!", organization);
        return { id: "new-organization-id" };
      },
      async get(organizationId) {
        return await getOrgById(organizationId);
      },
      async list() {
        return findOrgs();
      },
      accounts: {
        async find({ organizationId, ...options }) {
          return paginate(options)(findMemberships);
        },
        async remove(organizationId: string, accountId: string) {
          await wait();
          throwRandomError();
        }
      },
      partners: {
        async find({ organizationId, ...options }) {
          const result = await paginate(options)(findPartners);
          return result;
        }
      }
    },
    predictions: {
      async getModels({ imageId }) {
        await wait();
        throwRandomError(0.2);
        const modelNames = ["colon", "stomach"];
        return { data: modelNames, total: modelNames.length };
      },
      async create({ imageId, modelName }) {
        await wait();
        debug("Prediction requested");
        const predictions = await findPredictions();
        return { id: predictions[0].id, status: "waiting", modelName };
      },
      async retry(predictionId) {
        await wait();
        debug("Retry prediction request", predictionId);
        const predictions = await findPredictions();
        const { id, modelName } = predictions[0];
        return { id, status: "waiting", modelName };
      },
      async get(predictionId) {
        await wait();
        const predictions = await findPredictions();
        const getRandomStatus = () => {
          // Simulate the result of the polling process
          const value = Math.random();
          if (value > 0.9) return "failed";
          if (value > 0.7) return "completed";
          return "running";
        };
        const status = getRandomStatus();
        return {
          prediction: {
            ...predictions[0],
            id: predictionId,
            status,
            updatedAt: new Date()
          }
        };
      }
    },
    batchImport: {
      async parseFiles({ organizationId, filenames }) {
        debug("Parsing filenames", filenames, organizationId);
        const { data: cases } = await findCases();
        const result = filenames.map(filename => {
          const { caseNumber } = parseFilename(filename);
          const foundCase = cases.find(
            pathologicCase => pathologicCase.caseNumber === caseNumber
          );
          return {
            filename,
            caseNumber,
            caseId: foundCase?.id || null,
            exists: !!foundCase,
            fileAlreadyExists: filename.includes("duplicate")
          };
        });
        return result;
      }
    }
  };
}

function wait(ms = 500) {
  return new Promise<void>(resolve =>
    setTimeout(() => resolve(), 200 + Math.random() * ms)
  );
}

async function simulateProgress(onProgress) {
  const steps = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 0.95, 1]; // the last step is the most difficult ;)
  for (const step of steps) {
    await wait(5000);
    throwRandomError(0.002);
    onProgress(step);
  }
}

function parseFilename(filename) {
  const parts = filename.split("--");
  if (parts.length > 1)
    return {
      caseNumber: parts[0]
    };

  return {
    caseNumber: getFilenameWithoutExtension(filename)
  };
}

function getFilenameWithoutExtension(filename) {
  const index = filename.lastIndexOf(".");
  if (index !== -1) {
    return filename.slice(0, index);
  }
  return filename;
}

function generateRandomId(prefix: string) {
  return `${prefix}-${Date.now()}`;
}

function throwRandomError(probability = 0.5) {
  const randomNumber = Math.random();
  if (randomNumber < probability) {
    throw new Error(`Random error from the mock API`);
  }
}

function getImageTileURL(imageId: string) {
  // In the "mock" mode, we use the Image Server from the TEST environment in the Datastore world
  // it does not require authentication
  const imageServerRootURL = `https://image-server.images.test.medmain.com`;

  return `${imageServerRootURL}/iiif/2/pidport-datastore%2Fimages%2Fpyramidal%2F${imageId}.tiff`;
}
