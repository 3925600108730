import React, { useState } from "react";
import {
  Badge,
  Box,
  BoxProps,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useToast
} from "@chakra-ui/react";
import { MdMoreVert } from "react-icons/md";

import { useLocale } from "app/locale";
import { hasPermission } from "app/permissions";
import {
  Button,
  CheckIcon,
  DateTime,
  DeleteIcon,
  EditIcon,
  Textarea,
  useModal
} from "components/core";
import { getAccountDisplayName } from "models/account";
import { DialogDeleteComment } from "./dialog-delete-comment";

type CaseComment = Pick<Medmain.Comment, "id" | "message">;
type Props = {
  caseId: Medmain.Case["id"];
  comments: Medmain.Comment[];
  onUpdate: ({ id, message }: CaseComment) => void;
  onDeleteSuccess: (commentId: Medmain.Comment["id"]) => void;
};
export const CommentList = ({ comments, ...props }: Props) => {
  return (
    <Stack spacing={6}>
      {comments.map((comment, index) => (
        <CommentListItem
          key={comment.id}
          comment={comment}
          index={index}
          {...props}
        />
      ))}
    </Stack>
  );
};

type ItemProps = {
  caseId: Medmain.Case["id"];
  comment: Medmain.Comment;
  index: number;
  onUpdate: Props["onUpdate"];
  onDeleteSuccess: Props["onDeleteSuccess"];
};
const CommentListItem = ({
  caseId,
  comment,
  index,
  onUpdate,
  onDeleteSuccess,
  ...props
}: ItemProps) => {
  const locale = useLocale();

  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [isBeingSaved, setIsBeingSaved] = useState(false);
  const { id, author, edited } = comment;
  const toast = useToast();
  const modal = useModal();
  const authorDisplayName = getAccountDisplayName(author, locale);
  const canDelete = hasPermission("comment/delete", comment);
  const canUpdate = hasPermission("comment/update", comment);

  const showContextMenu = !isBeingEdited && (canUpdate || canDelete);

  const deleteComment = async () => {
    const deleted = await modal.dialog({
      render: close => (
        <DialogDeleteComment close={close} caseId={caseId} comment={comment} />
      )
    });
    if (!deleted) return false;
    onDeleteSuccess(id);
  };

  const updateComment = async ({ id, message }) => {
    setIsBeingSaved(true);
    try {
      await onUpdate({ id, message });
      toast({
        description: "Comment updated successfully",
        status: "success",
        isClosable: true
      });
    } catch (error) {
      toast({
        description: "Unable to update the comment",
        status: "error",
        isClosable: true
      });
      setIsBeingSaved(false);
    }
  };

  return (
    <Box data-testid="comment" {...props} borderWidth="1px" borderRadius="4px">
      <Flex
        fontSize="md"
        pl={4}
        pr={2}
        py={1}
        backgroundColor="gray.50"
        align="center"
      >
        <Box flexGrow={1}>
          <Stack isInline w="full">
            <Box minWidth={0} pt={2} pb={1}>
              <Box fontWeight="bold">{authorDisplayName}</Box>
              <Flex alignItems="center">
                <DateTime date={comment.createdAt} />
                {edited && (
                  <>
                    <Separator />
                    <Badge variant="outline">Edited</Badge>
                  </>
                )}
              </Flex>
            </Box>
          </Stack>
        </Box>
        <Box>
          {showContextMenu && (
            <Menu placement="bottom-end">
              <MenuButton
                as={IconButton}
                width="40px"
                height="40px"
                icon={<Icon as={MdMoreVert} boxSize="28px" />}
                borderRadius="99px"
                data-testid="comment-option"
              />
              <MenuList>
                <MenuItem
                  isDisabled={!canUpdate}
                  onClick={() => setIsBeingEdited(true)}
                >
                  <MenuIcon as={EditIcon} />
                  Edit comment
                </MenuItem>
                <MenuItem isDisabled={!canDelete} onClick={deleteComment}>
                  <MenuIcon as={DeleteIcon} />
                  Delete comment
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Box>
      </Flex>

      <CommentBody
        comment={comment}
        isBeingEdited={isBeingEdited}
        isUpdating={isBeingSaved}
        onCancel={() => setIsBeingEdited(false)}
        onSave={({ message }) => updateComment({ id, message })}
      />
    </Box>
  );
};

const Separator = (props: BoxProps) => (
  <Box as="span" color="gray.500" px={1} {...props}>
    •
  </Box>
);

const MenuIcon = (props: React.ComponentProps<typeof Icon>) => (
  <Icon color="gray.500" mr={2} boxSize="20px" {...props} />
);

const CommentBody = ({
  comment,
  isBeingEdited,
  isUpdating,
  onSave,
  onCancel
}) => {
  return isBeingEdited ? (
    <CommentEditMode
      comment={comment}
      onCancel={onCancel}
      onSave={onSave}
      isPending={isUpdating}
    />
  ) : (
    <CommentReadMode comment={comment} />
  );
};

const CommentReadMode = ({ comment }) => {
  const { message } = comment;
  // We need CSS to keep the multiple blank rows entered by the user
  return (
    <Box p={4} whiteSpace="pre-line">
      {message}
    </Box>
  );
};

const CommentEditMode = ({ comment, onSave, onCancel, isPending }) => {
  const [message, setMessage] = useState(comment.message);
  const onSubmit = event => {
    event.preventDefault();
    onSave({ message });
  };
  const onChange = event => {
    setMessage(event.target.value);
  };
  return (
    <Box p={4}>
      <form onSubmit={onSubmit}>
        <Textarea height="150px" value={message} onChange={onChange}></Textarea>
        <Flex mt={4} justifyContent="flex-end">
          <ButtonGroup>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              primary
              leftIcon={<CheckIcon />}
              type="submit"
              isLoading={isPending}
              isDisabled={!message}
            >
              Save
            </Button>
          </ButtonGroup>
        </Flex>
      </form>
    </Box>
  );
};
