import React, { useState } from "react";
import {
  Box,
  ListItem,
  Text,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ModalHeader,
  Alert,
  UnorderedList
} from "@chakra-ui/react";

import { useLocale } from "app/locale";
import { useAPI } from "api";
import { getAccountDisplayName } from "models/account";
import { Button, DateTime, ModalCloseButton, TimeAgo } from "components/core";

type Props = {
  close: (value: boolean) => void;
  caseId: Medmain.Case["id"];
  comment: Medmain.Comment;
};
export const DialogDeleteComment = ({ close, caseId, comment }: Props) => {
  const api = useAPI();
  const locale = useLocale();
  const [isBeingDeleted, setIsBeingDeleted] = useState(false);
  const [deleteError, setDeleteError] = useState<Error | undefined>(undefined);

  const { author, organizationName, createdAt } = comment;
  const authorDisplayName = getAccountDisplayName(author, locale);
  const date = new Date(createdAt);

  const onSubmit = async event => {
    event.preventDefault();
    setIsBeingDeleted(true);
    try {
      await api.cases.comments.delete({ caseId, commentId: comment.id });
      setIsBeingDeleted(false);
      close(true);
    } catch (error) {
      setDeleteError(error);
      setIsBeingDeleted(false);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <ModalHeader>
        {locale.todo("Delete Comment")}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text mb={4}>
          {locale.todo(
            "Are you sure you want to delete this comment for ever?"
          )}
        </Text>
        <UnorderedList styleType="disc">
          <ListItem>
            {locale.todo("Author")}: {authorDisplayName}
          </ListItem>
          <ListItem>
            {locale.todo("Group")}: {organizationName}
          </ListItem>
          <ListItem>
            {locale.todo("Date")}: <DateTime date={date} /> (
            <TimeAgo date={date} />)
          </ListItem>
        </UnorderedList>
        {deleteError && (
          <Alert status="error" flexDirection="column" alignItems="flex-start">
            <Box>{locale.todo("Unable to delete the comment")}</Box>
            {deleteError.message && <Box>{deleteError.message}</Box>}
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={() => close(false)} isDisabled={isBeingDeleted}>
            {locale.todo("Cancel")}
          </Button>
          <Button
            type="submit"
            colorScheme="red"
            variant="solid"
            isLoading={isBeingDeleted}
          >
            {locale.todo("Delete")}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};
