import { useLocale } from "app/locale";

type Role = { value: Medmain.OrgRole; label: string };

export function useRoles() {
  const locale = useLocale();

  const { proprietor, ...res } = locale.orgRoles;

  const availableRoles: Role[] = Object.entries(res).map(([value, label]) => ({
    value: value as Exclude<Medmain.OrgRole, "proprietor">,
    label
  }));

  const roles: Role[] = Object.entries(locale.orgRoles).map(
    ([value, label]) => ({
      value: value as Medmain.OrgRole,
      label
    })
  );

  const getRoleLabel = (role: Medmain.OrgRole): string => {
    const foundRole = roles.find(({ value, label }) => value === role);
    return foundRole?.label || role;
  };

  return { availableRoles, getRoleLabel };
}
