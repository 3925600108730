import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  showLeftSidebar: "768px",
  lg: "1024px",
  xl: "1280px",
  xl2: "1400px",
  xl3: "1600px"
});

// Capital color values are from Nikon Design
export const customTheme = extendTheme({
  breakpoints,
  colors: {
    gray: {
      0: "#FFFFFF",
      50: "#FBFBFB",
      100: "#F8F8F8",
      200: "#EDEEEF",
      250: "#E6E6E6",
      300: "#DFE2E3",
      400: "#CBD1D3",
      500: "#A6AFB5",
      600: "#858B90",
      650: "#777777",
      700: "#5C6064",
      800: "#4F5355",
      850: "#404040",
      900: "#373A3C",
      950: "#333333",
      960: "#2E2E2E",
      970: "#212121",
      1000: "#000000"
    },
    primary: {
      // Ref https://coolors.co/8e338e-e1eff6-97d2fb-83bcff-80ffe8
      50: "#FFFBE6",
      100: "#FFF6CC",
      200: "#FFEE99",
      300: "#FFE666",
      400: "#FFDD33",
      500: "#FFD500", // button bg
      600: "#FFD95C", // hover
      700: "#D1A000", // active
      800: "#411841",
      900: "#270e27"
    },
    orange: {
      400: "#BA4A13",
      500: "#DA6328",
      600: "#FA7C3C",
      900: "#FCB997"
    },
    blue: {
      50: "#FBFBFB",
      100: "#F8F8F8",
      200: "#EDEEEF",
      300: "#DFE2E3",
      400: "#CBD1D3",
      // 500: "#127ED8",
      500: "#373A3C",
      900: "#8AC6FA"
      // 900: "#111a39" // a specific color token "invertBgColor" would be better?
    },
    // Tokens migrated from Radium Starter TODO adjust the ChakraUI palette
    altTextColor: "rgba(0, 0, 0, 0.6)",
    backgroundColor: "#F8F8F8",
    dividerColor: "#cccccc",
    inverseAltTextColor: "rgba(255, 255, 255, 0.8)",
    inverseBoxBackgroundColor: "rgba(17, 26, 57, 0.7)", // transparent bg color applied to buttons and panels
    inverseBoxBorderColor: "rgba(255, 255, 255, 0.88)",
    inverseTextColor: "rgb(255, 255, 255)",
    inverseAccentColor: "#f983e3", // light pink, good contrast on dark background
    grayBackgroundColor: "#f5f8fb",
    statusBackgroundColor: "#f5f5f5",
    headerBackgroundColor: "#f3f7fa",
    mutedTextColor: "rgba(0, 0, 0, 0.38)",
    secondaryButtonColor: "#700A70",
    secondaryTextColor: "rgba(0, 0, 0, 0.6)",
    gray250Alpha: (alpha: number) => `rgba(230, 230, 230, ${alpha})`,
    gray650Alpha: (alpha: number) => `rgba(119, 119, 119, ${alpha})`,
    gray900Alpha: (alpha: number) => `rgba(55, 58, 60, ${alpha})`,
    primary500Alpha: (alpha: number) => `rgba(255, 213, 0, ${alpha})`,
    primary700Alpha: (alpha: number) => `rgba(209, 160, 0, ${alpha})`
  },
  fontWeights: {
    semibold: 500,
    // Migrated from Radium Starter
    strongFontWeight: "600"
  },
  shadows: {
    outline: "none"
  }
});

declare module "@emotion/react" {
  type CustomTheme = typeof customTheme;

  export interface Theme extends CustomTheme {}
}
