import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem as ChakraAccordionItem,
  AccordionPanel,
  Badge,
  Box,
  BoxProps
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useState } from "react";
import { isNikon } from "api";
import { scrollToTop } from "components/layout";
import { CaseAttachments } from "components/cases/case-attachments";
import { CaseEditDetails } from "components/cases/case-edit-details";
import { CaseDetailsReadonly } from "components/cases/case-details-readonly";
import { CasePermissions } from "pages/cases/case-item/case-item-permissions-page";
import { CaseComments } from "./case-item-comments-page-old";
import { FetchCaseState } from "./case-item-fetch-data";

export const PANEL_ANCHOR_ID = "info-panel";

const AccordionItem = styled(ChakraAccordionItem)`
  ${props => `
    button {
      &:focus[aria-expanded="true"] {
        box-shadow: 0 0 0 1px ${props.theme.colors.primary["700"]};
      }
    }
  `}
`;

type Props = Pick<FetchCaseState, "pathologicCase" | "reload"> & {
  isBeingEdited: boolean;
  setIsBeingEdited: (value: boolean) => void;
};

export const CaseItemInfoPanel = (props: Props) => {
  const { pathologicCase, reload } = props;
  const { attachments } = pathologicCase;
  const [numberOfComments, setNumberOfComments] = useState(0);
  const numberOfAttachments = attachments.length;

  return (
    <Accordion allowToggle data-testid="info-panel" id={PANEL_ANCHOR_ID}>
      <AccordionItem>
        <PanelHeader>Details</PanelHeader>
        <AccordionPanel pb={4}>
          <CaseDetailsPanel {...props} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <PanelHeader>
          Attachments
          {numberOfAttachments > 0 && (
            <Badge ml={2}>{numberOfAttachments}</Badge>
          )}
        </PanelHeader>
        <AccordionPanel pb={4}>
          <CaseAttachments pathologicCase={pathologicCase} reload={reload} />
        </AccordionPanel>
      </AccordionItem>
      {isNikon && (
        <AccordionItem>
          <PanelHeader>
            Comments
            {numberOfComments > 0 && <Badge ml={2}>{numberOfComments}</Badge>}
          </PanelHeader>
          <AccordionPanel pb={4}>
            <CaseComments
              pathologicCase={pathologicCase}
              setNumberOfComments={setNumberOfComments}
            />
          </AccordionPanel>
        </AccordionItem>
      )}
      {!isNikon && (
        <AccordionItem>
          <PanelHeader>Permissions</PanelHeader>
          <AccordionPanel pb={4}>
            <CasePermissions pathologicCase={pathologicCase} />
          </AccordionPanel>
        </AccordionItem>
      )}
    </Accordion>
  );
};

const PanelHeader = (props: BoxProps) => {
  return (
    <AccordionButton>
      <Box flex="1" textAlign="left" fontSize="16px" {...props} />
      <AccordionIcon />
    </AccordionButton>
  );
};

const CaseDetailsPanel = ({
  pathologicCase,
  reload,
  isBeingEdited,
  setIsBeingEdited
}: Props) => {
  const goToReadOnlyMode = () => {
    setIsBeingEdited(false);
    setTimeout(() => {
      scrollToTop();
    });
  };

  return isBeingEdited ? (
    <CaseEditDetails
      pathologicCase={pathologicCase}
      reload={reload}
      onSaveSuccess={goToReadOnlyMode}
      onCancel={goToReadOnlyMode}
      layout="vertical"
    />
  ) : (
    <CaseDetailsReadonly
      pathologicCase={pathologicCase}
      setEditMode={setIsBeingEdited}
    />
  );
};
