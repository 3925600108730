import styled from "@emotion/styled";
import React, { useState } from "react";
import { Link as RouterLink, useHistory, generatePath } from "react-router-dom";
import { Table as AntdTable } from "antd";
import { ColumnsType } from "antd/es/table";
import groupBy from "lodash/groupBy";
import {
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  Center,
  Checkbox,
  Flex,
  Grid,
  Icon,
  LinkProps,
  Stack,
  Text,
  Tooltip,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import { createContainer } from "unstated-next";
import { MdBrokenImage, MdHourglassEmpty } from "react-icons/md";
import { LocationDescriptorObject } from "history";

import { isNikon } from "api";
import { useLocale } from "app/locale";
import { useOrgLookup } from "app/auth-container";
import { hasPermission } from "app/permissions";
import { usePersistSearchParams } from "app/persist-search-params";
import {
  Button,
  CloseIcon,
  DateTime,
  ExpandIcon,
  Input,
  Link,
  SearchIcon,
  Table,
  TableSearchIcon
} from "components/core";
import { TagLink } from "components/cases/case-tags";
import { ImageLoader } from "components/images/image-loader";
import { sortCaseImages } from "components/images/image-utils";
import {
  CasePredictionSummary,
  ImageSummary
} from "components/images/prediction-summary";
import {
  TableStatusIcons,
  ToggledPrivateLabelledIcon,
  useLabelled
} from "components/labelled";
import { AssetIconImage, CommentIcon, FileIconImage } from "./case-icons";
import { SearchOptions, SortOrderDirection, SearchQueryItem } from "types";
import { CASE_DETAILS_PAGE, VIEWER_PAGE_SWITCHER } from "routes";
import { AgreementContainer } from "features/ai-legal-terms/agreement";

type Props = {
  canCreatePrediction: boolean;
  cases: Medmain.Case[];
  checkBox: string[];
  orderBy: string;
  orderDirection: SortOrderDirection;
  searchOptions: SearchOptions;
  total: number;
  getQueryField: (field: string) => SearchQueryItem | undefined;
  onToggleCheckBox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  reload: () => void;
  setSortOrder: (
    field: string,
    direction: SortOrderDirection | null
  ) => LocationDescriptorObject;
  setQueryField: (
    field: string,
    value: string | string[],
    operator?: string
  ) => LocationDescriptorObject;
  setCheckBox: React.Dispatch<React.SetStateAction<string[]>>;
};
export const CaseTable = ({
  canCreatePrediction,
  cases,
  checkBox,
  orderBy,
  orderDirection,
  searchOptions,
  total,
  getQueryField,
  onToggleCheckBox,
  reload,
  setCheckBox,
  setQueryField,
  setSortOrder
}: Props) => {
  const locale = useLocale();
  const history = useHistory();
  const { getOrgById } = useOrgLookup();
  const { togglePrivateLabelledCase, togglePublicLabelledCase } = useLabelled(
    reload
  );
  const { canUsePredictionFeatures } = AgreementContainer.useContainer();
  const [id, setId] = useState("");

  const canViewLabelled = (item: Medmain.Case) =>
    hasPermission("case/view_labelled", item);
  const canModPrivateLabelled = (item: Medmain.Case) =>
    hasPermission("case/mod_private_labelled", item);
  const canModPublicLabelled = (item: Medmain.Case) =>
    hasPermission("case/mod_public_labelled", item);

  const columns = [
    canCreatePrediction && AntdTable.SELECTION_COLUMN,
    isNikon
      ? undefined
      : {
          width: "58px",
          title: "",
          className: "labelled",
          render: (_, record: Medmain.Case) => {
            const { id: caseId, images } = record;
            if (!images.length) return null;
            return (
              <Center>
                {canViewLabelled(record) && (
                  <ToggledPrivateLabelledIcon
                    privateLabelled={images.some(
                      image => image.privateLabelled
                    )}
                    onClick={() => {
                      togglePrivateLabelledCase.run({ caseId, images });
                      setId(caseId);
                    }}
                    size={26}
                    isDisabled={
                      !canModPrivateLabelled(record) ||
                      (caseId === id && togglePrivateLabelledCase.isPending)
                    }
                  />
                )}
              </Center>
            );
          }
        },
    AntdTable.EXPAND_COLUMN,
    {
      width: "237px",
      key: "caseNumber",
      sortOrder: getColumnSortOrder("caseNumber")(orderBy, orderDirection),
      title: locale.caseFields.caseNumber,
      dataIndex: "caseNumber",
      sorter: true,
      showSorterTooltip: false,
      filtered: !!getQueryField("caseNumber"),
      filterIcon: TableSearchIcon,
      filterDropdown: () => (
        <QuickSearchCaseNumber
          caseNumber={getQueryField("caseNumber")?.value as string | undefined}
          onSearch={value => {
            const nextLocation = setQueryField("caseNumber", value, "is-like");
            history.push(nextLocation);
          }}
          onReset={() => {
            const nextLocation = setQueryField("caseNumber", "");
            history.push(nextLocation);
          }}
        />
      ),
      render: (caseNumber, { id: caseId, images }, index) => {
        return (
          <CaseLocationProvider
            initialState={{ caseId, index, cases, searchOptions, total }}
          >
            <CaseLink
              route={CASE_DETAILS_PAGE.route}
              title={caseNumber}
              display="inline-block"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              maxWidth="220px"
            >
              {caseNumber}
            </CaseLink>
            {canUsePredictionFeatures &&
              images.some(image => image.predictionSummary) && (
                <Box mt={2}>
                  <CasePredictionSummary images={images} />
                </Box>
              )}
          </CaseLocationProvider>
        );
      }
    },
    {
      width: "181px",
      title: "",
      render: (
        _,
        { id: caseId, attachments, images, numberOfComments, tags },
        index
      ) => {
        return (
          <>
            <Stack isInline spacing={4}>
              {images?.length > 0 && (
                <Box>
                  <CaseIcon as={FileIconImage} />
                  {images.length}
                </Box>
              )}
              {attachments?.length > 0 && (
                <Box>
                  <CaseIcon as={AssetIconImage} />
                  {attachments.length}
                </Box>
              )}
              {numberOfComments > 0 && (
                <Box>
                  <CaseIcon as={CommentIcon} />
                  {numberOfComments}
                </Box>
              )}
            </Stack>
            <ImageBadges images={images} />
          </>
        );
      }
    },
    {
      width: "177px",
      title: locale.caseFields.organization,
      dataIndex: "organizationId",
      render: organizationId => {
        const orgName = getOrgById(organizationId)?.name || locale.unknownOrg;
        return (
          <Link
            as={RouterLink}
            to={`/organizations/${organizationId}`}
            _hover={{ textDecoration: "none" }}
            title={orgName}
            display="inline-block"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            maxWidth="145px"
          >
            {orgName}
          </Link>
        );
      }
    },
    {
      width: "225px",
      title: locale.caseFields.tags,
      dataIndex: "tags",
      className: "tags-column",
      render: tags => {
        if (!tags.length) return null;
        return <Tags tags={tags} setQueryField={setQueryField} />;
      }
    },
    isNikon
      ? undefined
      : {
          width: "156px",
          title: locale.caseFields.status,
          className: "status-column",
          render: (_, record: Medmain.Case) => {
            const { id: caseId, images } = record;
            const publicLabelleds = getCommonStatusFromImages(images, caseId);
            const canModPublicLabelledCase = canModPublicLabelled(record);
            return (
              <Box>
                {canViewLabelled(record) && images.length > 0 && (
                  <TableStatusIcons
                    publicLabelleds={publicLabelleds}
                    onClick={({ name, color }) => {
                      togglePublicLabelledCase.run({
                        caseId,
                        images,
                        name,
                        color
                      });
                    }}
                    isActive={({ name, color }) =>
                      publicLabelleds.some(
                        item =>
                          item.caseId === caseId &&
                          item.name === name &&
                          item.color === color
                      )
                    }
                    isDisabled={
                      !canModPublicLabelledCase ||
                      togglePublicLabelledCase.isPending
                    }
                    canAddIcon={canModPublicLabelledCase}
                  />
                )}
              </Box>
            );
          }
        },
    {
      width: "126px",
      title: locale.caseList.createdAt,
      dataIndex: "createdAt",
      sorter: true,
      showSorterTooltip: false,
      // TODO Allow multiple sort filters at the same time?
      sortDirections: ["ascend", "descend", "ascend"], // "to prevent sorter back to default status." as mentioned here https://ant.design/components/table/
      sortOrder: getColumnSortOrder("createdAt")(orderBy, orderDirection),
      render: createdAt => {
        return <DateTime date={createdAt} mask="yyyy-MM-dd" />;
      }
    }
  ].filter(value => !!value) as ColumnsType<Medmain.Case>;

  const onChange = (pagination, filters, sorter) => {
    const orderDirections = {
      ascend: "ASC",
      descend: "DESC"
    };
    const order: SortOrderDirection = orderDirections[sorter.order] || null;
    const nextLocation = setSortOrder(sorter.field, order);
    history.push(nextLocation);
  };

  const rowSelection = {
    columnTitle: <></>,
    columnWidth: 30,
    renderCell: (_, record: Medmain.Case) => (
      <Center>
        {canCreatePrediction && record.images.length > 0 && (
          <Checkbox
            colorScheme="primary"
            value={record.id}
            isChecked={record.images.some(image => checkBox.includes(image.id))}
            onChange={() => {
              if (record.images.some(image => checkBox.includes(image.id))) {
                return setCheckBox(
                  checkBox.filter(
                    id => !record.images.map(image => image.id).includes(id)
                  )
                );
              }
              return setCheckBox([
                ...checkBox,
                ...record.images
                  .map(image => image.id)
                  .filter(id => !checkBox.includes(id))
              ]);
            }}
          />
        )}
      </Center>
    )
  };

  return (
    <Wrapper>
      <Table
        dataSource={cases}
        columns={columns}
        rowKey={record => record.id}
        bordered={false}
        showHeader={true}
        pagination={false}
        shouldShowHoverBackground={true}
        onChange={onChange}
        rowSelection={canCreatePrediction ? rowSelection : undefined}
        rowClassName={record =>
          record.images.some(image => checkBox.includes(image.id))
            ? "isChecked"
            : ""
        }
        expandable={{
          columnWidth: "28px",
          expandedRowRender: (item, index) => {
            const sortedImages = sortCaseImages(item);
            return (
              <CaseLocationProvider
                initialState={{
                  caseId: item.id,
                  index,
                  cases,
                  searchOptions,
                  total
                }}
              >
                <CaseImageTable
                  images={sortedImages}
                  canViewLabelled={canViewLabelled(item)}
                  canModPrivateLabelled={canModPrivateLabelled(item)}
                  canModPublicLabelled={canModPublicLabelled(item)}
                  checkBox={checkBox}
                  onToggleCheckBox={onToggleCheckBox}
                  reload={reload}
                  canCreatePrediction={canCreatePrediction}
                />
              </CaseLocationProvider>
            );
          },
          rowExpandable: ({ images, attachments }) => {
            return images?.length > 0;
          },
          expandIcon: ({ expanded, onExpand, record, expandable }) => {
            if (!expandable) return null;
            return (
              <ExpandIcon
                pl="10px"
                expanded={expanded}
                onClick={event => onExpand(record, event)}
              />
            );
          }
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  min-height: 30rem;

  & > * {
    min-width: 1195px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  tr.ant-table-expanded-row > td {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 50px;
    padding-right: 126px;
  }
  .isChecked {
    background-color: #f9f4f9;
  }
  .labelled::before {
    background: none !important;
  }
  /* Needed to avoid long "tags" column, whose width not fixed, taking too much space */
  .tags-column {
    word-break: break-all;
  }
  tr.ant-table-expanded-row > td.ant-table-cell .status-column {
    padding: 16px;
  }
`;

const getColumnSortOrder = (columnKey: string) => (
  orderBy: string,
  orderDirection: SortOrderDirection
) => {
  if (columnKey !== orderBy) return null;
  if (orderDirection === "ASC") return "ascend";
  if (orderDirection === "DESC") return "descend";
  return null;
};

export const getCommonStatusFromImages = (
  images: Medmain.Image[],
  caseId: Medmain.Case["id"]
) => {
  const iconsByLabelName = groupBy(
    images
      .flatMap(image => image.publicLabelleds)
      .filter(labelled => !!labelled),
    "labelName"
  );
  return Object.keys(iconsByLabelName)
    .filter(icon => iconsByLabelName[icon].length === images.length)
    .map(icon => ({
      caseId,
      name: icon.split("-")[0],
      color: icon.split("-")[1]
    }));
};

const CaseIcon = (props: React.ComponentProps<typeof Icon>) => (
  <Icon fontSize="24px" mr={1} color="gray.500" {...props} />
);

const QuickSearchCaseNumber = ({
  onSearch,
  onReset,
  caseNumber
}: {
  onSearch: (value: string) => void;
  onReset: () => void;
  caseNumber?: string;
}) => {
  const locale = useLocale();
  const [value, setValue] = useState(caseNumber || "");

  const onSubmit = event => {
    event.preventDefault();
    onSearch(value);
  };

  return (
    <Box onKeyDownCapture={e => e.stopPropagation()}>
      <Box as="form" p={4} width={240} onSubmit={onSubmit}>
        <Input
          placeholder={locale.caseFields.caseNumber}
          value={value}
          onChange={event => {
            setValue(event.target.value);
          }}
          autoFocus
        />
        <Grid templateColumns="1fr 50%" gap={4} mt={4}>
          <Box>
            <Button
              type="button"
              onClick={() => {
                setValue("");
                onReset();
              }}
              leftIcon={<CloseIcon />}
              width="100%"
            >
              {locale.resetButtonLabel}
            </Button>
          </Box>
          <Box>
            <Button
              type="submit"
              primary
              leftIcon={<SearchIcon />}
              width="100%"
            >
              {locale.searchButtonLabel}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

const CaseImageTable = ({
  images,
  canCreatePrediction,
  checkBox,
  canViewLabelled,
  canModPrivateLabelled,
  canModPublicLabelled,
  onToggleCheckBox,
  reload
}: {
  images: Medmain.Image[];
  canCreatePrediction: boolean;
  checkBox: string[];
  canViewLabelled: boolean;
  canModPrivateLabelled: boolean;
  canModPublicLabelled: boolean;
  onToggleCheckBox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  reload: () => void;
}) => {
  const locale = useLocale();
  const { togglePrivateLabelledImage, togglePublicLabelledImage } = useLabelled(
    reload
  );
  const [id, setId] = useState("");

  const columns: ColumnsType<Medmain.Image> = [
    isNikon
      ? undefined
      : {
          title: "",
          className: "labelled",
          dataIndex: "privateLabelled",
          width: 58,
          render: (
            privateLabelled: Medmain.Image["privateLabelled"],
            image
          ) => {
            return (
              <Center>
                {canViewLabelled && (
                  <ToggledPrivateLabelledIcon
                    privateLabelled={!!privateLabelled}
                    onClick={() => {
                      togglePrivateLabelledImage.run({
                        imageId: image.id,
                        privateLabelled
                      });
                      setId(image.id);
                    }}
                    size={26}
                    isDisabled={
                      !canModPrivateLabelled ||
                      (image.id === id && togglePrivateLabelledImage.isPending)
                    }
                  />
                )}
              </Center>
            );
          }
        },
    {
      key: "thumbnail",
      title: locale.imageList.thumbnail,
      width: 100,
      render: (_, image) => {
        return <ImageMiniThumbnail image={image} />;
      }
    },
    {
      dataIndex: "displayName",
      title: locale.imageList.fileName,
      render: (displayName, image) => {
        if (image.status !== "available") {
          return (
            <>
              {displayName}
              <ImageStatusBadge image={image} ml={2} />
            </>
          );
        }

        return (
          <>
            <CaseLink
              route={VIEWER_PAGE_SWITCHER.route}
              params={{ imageId: image.id }}
              title={displayName}
              display="inline-block"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              maxWidth="658px"
            >
              <Text as="u">{displayName}</Text>
            </CaseLink>
            <ImageSummary image={image} mt={2} />
          </>
        );
      }
    },
    isNikon
      ? undefined
      : {
          title: locale.caseFields.status,
          dataIndex: "publicLabelleds",
          width: 156,
          className: "status-column",
          render: (
            publicLabelleds: Medmain.Image["publicLabelleds"],
            image
          ) => {
            if (!canViewLabelled) return null;
            return (
              <TableStatusIcons
                publicLabelleds={publicLabelleds}
                onClick={({ name, color }) => {
                  togglePublicLabelledImage.run({
                    imageId: image.id,
                    publicLabelled: publicLabelleds?.find(
                      publicLabelled =>
                        publicLabelled.labelName === `${name}-${color}`
                    ),
                    name,
                    color
                  });
                }}
                isActive={({ name, color }) =>
                  publicLabelleds?.some(
                    item =>
                      item.labelName.split("-")[0] === name &&
                      item.labelName.split("-")[1] === color
                  ) ?? false
                }
                isDisabled={
                  !canModPublicLabelled || togglePublicLabelledImage.isPending
                }
                canAddIcon={canModPublicLabelled}
              />
            );
          }
        }
  ].filter(value => !!value) as ColumnsType<Medmain.Image>;

  const rowSelection = {
    columnWidth: 54,
    renderCell: (_, record: Medmain.Image) => (
      <Center>
        {record.status === "available" && (
          <Checkbox
            colorScheme="primary"
            value={record.id}
            onChange={onToggleCheckBox}
            isChecked={checkBox.includes(record.id)}
          />
        )}
      </Center>
    )
  };

  return (
    <Table
      dataSource={images}
      columns={columns}
      rowKey={image => image.id}
      size="small"
      bordered={false}
      showHeader={false}
      pagination={false}
      shouldShowHoverBackground={true}
      rowSelection={canCreatePrediction ? rowSelection : undefined}
      rowClassName={record => (checkBox.includes(record.id) ? "isChecked" : "")}
    />
  );
};

const ImageMiniThumbnail = ({ image }: { image: Medmain.Image }) => {
  const { status } = image;
  const size = 80;

  const ImageIcon = () => {
    if (["uploaded", "processing"].includes(status)) {
      return <Icon as={MdHourglassEmpty} boxSize="40px" color="gray.500" />;
    }
    return <Icon as={MdBrokenImage} boxSize="40px" color="red.300" />;
  };

  if (status !== "available") {
    return (
      <ThumbnailContainer boxSize={`${size}px`}>
        <ImageIcon />
      </ThumbnailContainer>
    );
  }
  return (
    <CaseLink
      route={VIEWER_PAGE_SWITCHER.route}
      params={{ imageId: image.id }}
      display="flex"
      alignItems="center"
      width={`${size}px`}
    >
      <ThumbnailContainer boxSize={`${size}px`}>
        <ImageLoader image={image} />
      </ThumbnailContainer>
    </CaseLink>
  );
};

const ThumbnailContainer = (props: BoxProps) => {
  return <Flex align="center" justify="center" borderWidth="1px" {...props} />;
};

export const MagnificationBadge = ({
  value,
  ...props
}: { value: Medmain.Image["magnification"] } & BadgeProps) => {
  if (!value) return null;
  const color = value === 40 ? "teal" : "cyan";

  return (
    <Badge colorScheme={color} {...props}>
      x{value}
    </Badge>
  );
};

const CaseLink = ({
  route,
  params,
  ...props
}: {
  route: string;
  params?: Record<string, string>;
} & LinkProps) => {
  const getCaseLocation = CaseLocationContainer.useContainer();
  const to = getCaseLocation(route, params);
  return <Link as={RouterLink} to={to} {...props} />;
};

type ImageStatusBadgeProps = { image: Medmain.Image } & BadgeProps;
const ImageStatusBadge = ({ image, ...props }: ImageStatusBadgeProps) => {
  const locale = useLocale();
  const { status } = image;

  const settings = imageStatuses[status];
  if (!settings) return null; // don't show anything for available images

  return (
    <Badge
      variant="solid"
      colorScheme={settings.color}
      textTransform="none"
      {...props}
    >
      {locale.imageStatuses[status]}
    </Badge>
  );
};

const imageStatuses = {
  uploaded: {
    color: "yellow",
    icon: <Icon as={MdHourglassEmpty} boxSize="24px" color="yellow.500" />
  },
  processing: {
    color: "orange",
    icon: <Icon as={MdHourglassEmpty} boxSize="24px" color="orange.500" />
  },
  process_failed: {
    color: "red",
    icon: <Icon as={MdBrokenImage} boxSize="24px" color="red.500" />
  }
};

const ImageBadges = ({ images }: { images: Medmain.Image[] }) => {
  const locale = useLocale();

  return (
    <>
      {Object.keys(imageStatuses).map(status => {
        const count = images.filter(image => image.status === status).length;
        if (!count) return null;
        const color = imageStatuses[status].color + ".500";
        const label = `${locale.imageStatuses[status]}: ${count}`;

        return (
          <Tooltip
            key={status}
            label={label}
            aria-label={label}
            placement="right"
          >
            <Flex mt={2} width="fit-content">
              {imageStatuses[status].icon}
              <Box as="span" color={color} ml={1}>
                {count}
              </Box>
            </Flex>
          </Tooltip>
        );
      })}
    </>
  );
};

const Tags = ({
  tags,
  setQueryField
}: {
  tags: string[];
  setQueryField: Props["setQueryField"];
}) => {
  return (
    <Wrap>
      {tags.map(tag => {
        const nextLocation = setQueryField("tags", [tag], "contains-any");
        return (
          <WrapItem key={tag} maxWidth="100%">
            <TagLink to={nextLocation} title={tag} maxW="84px" px="5px">
              {tag}
            </TagLink>
          </WrapItem>
        );
      })}
    </Wrap>
  );
};

type CaseLocationState = {
  caseId: Medmain.Case["id"];
  index: number;
  searchOptions: SearchOptions;
  total: number;
  cases: Medmain.Case[];
};
function useCaseLocation(initialState?: CaseLocationState) {
  if (!initialState)
    throw new Error(`"CaseLocation" container is not initialized correctly"`);
  const { caseId, index, searchOptions, total, cases } = initialState;
  const { getItemLocation } = usePersistSearchParams(searchOptions);

  const getCaseLocation = (route: string, params?: any) => {
    const pathName = generatePath(route, { caseId, ...params });
    const caseLocation = getItemLocation(
      pathName,
      [cases[index - 1]?.id, cases[index + 1]?.id],
      index.toString(),
      total.toString()
    );
    return caseLocation;
  };

  return getCaseLocation;
}

const CaseLocationContainer = createContainer(useCaseLocation);
const CaseLocationProvider = CaseLocationContainer.Provider;
